import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Collapse,
  Typography,
  Divider,
  Paper,
  Grid
} from '@mui/material';
import { useAuth } from '../../contexts/useAuthState';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { updateUserProfile } from '../../services/AuthService';
import { formatTelefone } from '../../utils/utils';

const UpdateProfileForm = () => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    displayName: '',
    email: '',
    phone: '',
    type: '',
    isApproved: false,
    metadata: {
      createdAt: '',
      lastLogin: '',
      status: ''
    }
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (currentUser?.uid) {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          const userData = userDoc.data();

          if (userData) {
            setFormData({
              displayName: userData.name || currentUser?.displayName || '',
              email: userData.email || currentUser?.email || '',
              phone: userData.phone || '',
              type: userData.type || '',
              isApproved: userData.isApproved || false,
              metadata: {
                createdAt: userData.metadata?.createdAt || '',
                lastLogin: userData.metadata?.lastLogin || '',
                status: userData.metadata?.status || ''
              }
            });
          }
        }
        setInitialLoad(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Erro ao carregar dados do usuário');
        setInitialLoad(false);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      setFormData(prev => ({
        ...prev,
        [name]: formatTelefone(value)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const validateForm = () => {
    if (!formData.displayName.trim()) {
      setError('O nome não pode estar vazio');
      return false;
    }
    if (!formData.phone.trim()) {
      setError('O telefone não pode estar vazio');
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      // Update user profile in Firebase Auth
      await updateUserProfile({
        displayName: formData.displayName.trim(),
        email: formData.email,
        phone: formData.phone
      });

      // Update user document in Firestore
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        name: formData.displayName.trim(),
        phone: formData.phone,
        'metadata.updatedAt': new Date().toISOString()
      });

      setSuccessMessage('Informações atualizadas com sucesso!');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Erro ao atualizar informações do perfil');
    } finally {
      setLoading(false);
    }
  };

  if (initialLoad) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%' }}>
      <Paper sx={{ p: 3, mb: 3 }} elevation={0}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Informações Básicas
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Endereço de Email"
          name="email"
          value={formData.email}
          disabled
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="displayName"
          label="Nome Completo"
          id="displayName"
          value={formData.displayName}
          onChange={handleChange}
          error={!formData.displayName.trim()}
          helperText={!formData.displayName.trim() ? 'Nome é obrigatório' : ''}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="phone"
          label="Telefone"
          id="phone"
          value={formData.phone}
          onChange={handleChange}
          error={!formData.phone.trim()}
          helperText={!formData.phone.trim() ? 'Telefone é obrigatório' : ''}
        />
      </Paper>

      <Paper sx={{ p: 3, mb: 3 }} elevation={0}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Informações da Conta
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Tipo de Usuário"
              value={formData.type === 'agency_owner' ? 'Proprietário' : 'Usuário'}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Status da Conta"
              value={formData.isApproved ? 'Aprovado' : 'Pendente'}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Data de Criação"
              value={formData.metadata.createdAt ? new Date(formData.metadata.createdAt).toLocaleDateString() : 'N/A'}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Último Acesso"
              value={formData.metadata.lastLogin ? new Date(formData.metadata.lastLogin).toLocaleDateString() : 'N/A'}
              disabled
            />
          </Grid>
        </Grid>
      </Paper>

      <Button
        type="submit"
        variant="contained"
        sx={{
          mt: 1,
          mb: 2,
          borderRadius: '50px',
          ml: 'auto', 
          display: 'block' 
        }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Salvar Alterações'}
      </Button>


      <Collapse in={!!error} timeout={500}>
        <Box sx={{ width: '100%', mb: 2 }}>
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
      </Collapse>

      <Collapse in={!!successMessage} timeout={500}>
        <Box sx={{ width: '100%', mb: 2 }}>
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
        </Box>
      </Collapse>
    </Box>
  );
};

export default UpdateProfileForm;