import { db, auth } from '../firebaseConfig';
import { collection, doc, addDoc, getDocs, updateDoc, query, orderBy, where, writeBatch, getDoc, limit, startAfter } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { validateDocumentDuplication } from './PassengerService';

const getAgencyRef = async (userId) => {
    if (!userId) {
        throw new Error('User ID is required to get agency reference');
    }
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
        throw new Error('User document not found');
    }

    return doc(db, 'agencies', userDoc.data().agencyId);
};

const agencyHasPassengers = async (userId) => {
    try {
        if (!userId) {
            throw new Error('User ID is required to check passengers');
        }
        const agencyRef = await getAgencyRef(userId);
        const passengersRef = collection(agencyRef, 'passengers');
        const q = query(
            passengersRef,
            where('agencyId', '==', agencyRef.id)
        );

        const snapshot = await getDocs(q);
        return !snapshot.empty;
    } catch (error) {
        console.error('Error checking agency passengers:', error);
        throw error;
    }
};

const checkAndUpdateAdultStatus = async (passenger, userId) => {
    try {
        if (!userId) {
            throw new Error('User ID is required to check adult status');
        }

        const today = new Date();
        const birthDate = new Date(passenger.dataNascimento);

        // Age calculation
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();

        // Adjust age if birthday hasn't occurred this year
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        // Check if passenger is underage
        const menorDeIdade = age < 18;

        // Update in database if status changed
        if (passenger.menorDeIdade !== menorDeIdade) {
            const agencyRef = await getAgencyRef(userId);
            const passengerRef = doc(agencyRef, 'passengers', passenger.id);

            await updateDoc(passengerRef, {
                menorDeIdade,
                updatedAt: new Date().toISOString()
            });
        }

        // Return passenger with updated status
        return {
            ...passenger,
            menorDeIdade
        };
    } catch (error) {
        console.error('Error updating underage status:', error);
        // Return passenger without changes in case of error
        return passenger;
    }
};

export const addPassenger = async (passenger, userId) => {
    try {
        if (!userId) {
            throw new Error('User ID is required to add passenger');
        }
        const agencyRef = await getAgencyRef(userId);
        const passengersRef = collection(agencyRef, 'passengers');

        // Validate document duplication
        await validateDocumentDuplication(passenger, userId);  // Added userId here

        passenger = await checkAndUpdateAdultStatus(passenger, userId);  // Added userId here

        const timestamp = new Date().toISOString();
        const passengerWithMetadata = {
            ...passenger,
            estaAtivo: true,
            metadata: {
                createdAt: timestamp,
                updatedAt: timestamp,
                deletedAt: null
            }
        };

        return await addDoc(passengersRef, passengerWithMetadata);
    } catch (error) {
        console.error('Error adding passenger:', error);
        throw error;
    }
};

const agencyHasVehicles = async (userId) => {
    try {
        if (!userId) {
            throw new Error('User ID is required to check vehicles');
        }
        const agencyRef = await getAgencyRef(userId);
        const vehiclesRef = collection(agencyRef, 'vehicles');
        const q = query(vehiclesRef, where('estaAtivo', '==', true));
        const snapshot = await getDocs(q);
        return !snapshot.empty;
    } catch (error) {
        console.error('Error checking agency vehicles:', error);
        throw error;
    }
};

export const agencyHasLayouts = async (userId) => {
    try {
        if (!userId) {
            throw new Error('User ID is required to check layouts');
        }
        const agencyRef = await getAgencyRef(userId);
        const layoutsRef = collection(agencyRef, 'layouts');
        const q = query(layoutsRef, where('estaAtivo', '==', true));
        const snapshot = await getDocs(q);
        return !snapshot.empty;
    } catch (error) {
        console.error('Error checking agency layouts:', error);
        throw error;
    }
};

export const createDefaultPassengerForNewAgency = async (userId) => {
    try {
        if (!userId) {
            throw new Error('User ID is required to create default passenger');
        }

        const hasPassengers = await agencyHasPassengers(userId);
        const agencyRef = await getAgencyRef(userId);

        if (!hasPassengers) {
            const defaultPassenger = {
                nome: 'Passageiro Padrão',
                dataNascimento: '2000-01-01',
                telefone: '12345678910',
                endereco: 'Endereço Padrão, 000',
                estrangeiro: false,
                cpf: '00000000000',
                rg: '00000000',
                passaporte: null,
                menorDeIdade: false,
                estaAtivo: true,
                dataAdicionado: new Date().toISOString(),
                dataExclusao: null,
                agencyId: agencyRef.id
            };

            await addPassenger(defaultPassenger, userId);
        }
    } catch (error) {
        console.error('Error creating default passenger:', error);
        throw error;
    }
};

export const createDefaultVehicleForNewAgency = async (userId) => {
    try {
        if (!userId) {
            throw new Error('User ID is required to create default vehicle');
        }

        const hasVehicles = await agencyHasVehicles(userId);

        if (!hasVehicles) {
            const defaultLayoutId = await createDefaultLayoutForNewAgency(userId);

            if (!defaultLayoutId) {
                console.error('Error: Default layout ID is null or undefined.');
                return;
            }

            const agencyRef = await getAgencyRef(userId);
            const defaultVehicle = {
                identificadorVeiculo: 'Veículo Padrão',
                placa: `ABC-1234`,
                empresa: 'Empresa Padrão',
                layoutId: defaultLayoutId,
                estaAtivo: true,
                dataExclusao: null,
                agencyId: agencyRef.id
            };

            return await addVehicle(defaultVehicle, userId);
        }
        return null;
    } catch (error) {
        console.error('Error creating default vehicle:', error);
        throw error;
    }
};

export const addVehicle = async (vehicle, userId) => {
    try {
        if (!userId) {
            throw new Error('User ID is required to add vehicle');
        }
        const agencyRef = await getAgencyRef(userId);
        const vehiclesRef = collection(agencyRef, 'vehicles');
        const dataAdicionado = new Date().toISOString();

        const docRef = await addDoc(vehiclesRef, {
            ...vehicle,
            estaAtivo: true,
            dataExclusao: null,
            dataAdicionado,
            agencyId: agencyRef.id
        });

        return docRef;
    } catch (error) {
        console.error('Error adding vehicle:', error);
        throw error;
    }
};

export const createDefaultLayoutForNewAgency = async (userId) => {
    try {
        if (!userId) {
            throw new Error('User ID is required to create default layout');
        }
        const hasLayouts = await agencyHasLayouts(userId);

        if (!hasLayouts) {
            const agencyRef = await getAgencyRef(userId);
            const { firstFloorLayout, secondFloorLayout } = initializeDefaultLayout();

            const layoutData = {
                name: 'Layout Padrão',
                firstFloor: firstFloorLayout,
                secondFloor: secondFloorLayout,
                assentosAndar1: firstFloorLayout.filter(cell => cell.type === 'seat').length,
                assentosAndar2: secondFloorLayout.filter(cell => cell.type === 'seat').length,
                assentosTotais: firstFloorLayout.filter(cell => cell.type === 'seat').length +
                    secondFloorLayout.filter(cell => cell.type === 'seat').length,
                doisAndares: true,
                agencyId: agencyRef.id
            };

            const layoutId = await addLayout(layoutData, userId);
            return layoutId || null;
        }
        return null;
    } catch (error) {
        console.error('Error creating default layout:', error);
        throw error;
    }
};

export const addLayout = async (layout, userId) => {
    try {
        if (!userId) {
            throw new Error('User ID is required to add layout');
        }
        const agencyRef = await getAgencyRef(userId);
        const layoutsRef = collection(agencyRef, 'layouts');

        const timestamp = new Date().toISOString();
        const layoutWithMetadata = {
            ...layout,
            estaAtivo: true,
            metadata: {
                createdAt: timestamp,
                updatedAt: timestamp,
                deletedAt: null
            }
        };

        const docRef = await addDoc(layoutsRef, layoutWithMetadata);
        return docRef.id;
    } catch (error) {
        console.error('Error adding layout:', error);
        throw error;
    }
};

// Função para inicializar o layout padrão com 2 andares
const initializeDefaultLayout = () => {
    const rows = 10;
    const cols = 4;

    // Inicializa o layout do primeiro andar
    const firstFloorLayout = [];
    let seatNumber = 1;
    for (let i = 0; i < rows; i++) {
        const row = [];
        for (let j = 0; j < cols; j++) {
            // Adiciona assentos e outros tipos de assentos em posições específicas
            if (i === 2 && j === 1) {
                row.push({ type: 'bathroom', number: null });
            } else if (i === 5 && j === 2) {
                row.push({ type: 'fridge', number: null });
            } else {
                row.push({ type: 'seat', number: seatNumber++ });
            }
        }
        firstFloorLayout.push(row);
    }

    // Inicializa o layout do segundo andar
    const secondFloorLayout = [];
    for (let i = 0; i < rows; i++) {
        const row = [];
        for (let j = 0; j < cols; j++) {
            if (i === 3 && j === 0) {
                row.push({ type: 'bathroom', number: null });
            } else if (i === 7 && j === 3) {
                row.push({ type: 'fridge', number: null });
            } else {
                row.push({ type: 'seat', number: seatNumber++ });
            }
        }
        secondFloorLayout.push(row);
    }

    return {
        firstFloorLayout: flattenLayout(firstFloorLayout),
        secondFloorLayout: flattenLayout(secondFloorLayout),
    };
};

// Função para "achatar" o layout em uma estrutura linear
const flattenLayout = (layout) => {
    const flatLayout = [];
    layout.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
            flatLayout.push({
                row: rowIndex,
                col: colIndex,
                type: cell.type,
                number: cell.number,
            });
        });
    });
    return flatLayout;
};
