import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/useAuthState';
import { DrawerProvider } from './contexts/DrawerContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CircularProgress, Typography, Box, CssBaseline } from '@mui/material';
import { PrivateRoute } from './contexts/PrivateRoute';
import { BusinessRoute } from './contexts/BusinessRoute'
import getLPTheme from './components/newlandingpage/theme/getLPTheme';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import PassengerPage from './pages/PassengerPage';
import TravelPage from './pages/TravelPage';
import TravelDetails from './components/travels/TravelDetails';
import TravelCosts from './pages/TravelCosts';
import PassengerAllocation from './components/allocation/PassengerAllocation';
import TravelOrderReservationPage from './components/travels/TravelOrderReservationPage';
import VehiclePage from './pages/VehiclePage';
import MyAccount from './pages/MyAccount';
import HelpCenter from './pages/HelpCenter';
import ReportPage from './pages/ReportPage';
import Receivables from './components/payments/Receivables';
import NewLandingPage from './components/newlandingpage/NewLandingPage';
import PendingApprovalPage from './pages/PendingApprovalPage';
import ManageBusLayoutPage from './pages/ManageBusLayoutPage';
import BusLayoutBuilderPage from './components/seatlayout/BusLayoutBuilderPage';
import OrderReservationList from './components/payments/OrderReservationList';
// import UserManagement from './components/userManagement/UserManagement';
import Layout from './components/common/Layout';
import TravelLoading from './components/common/TravelLoading';
// descomentar a linha abaixo apenas quando for necessário rodar as migrations
// import MigrationTestPage from './migrations/MigrationTestPage';
import './App.css';
import UpdateAgencyForm from './components/account/UpdateAgencyForm';

function AuthenticatedApp({ toggleColorMode, mode }) {
  const { currentUser, loading } = useAuth();
  const location = useLocation();

  const shouldShowSidebar = !['/login', '/pendente-aprovacao'].includes(location.pathname);
  const shouldHideLogout = ['/login', '/pendente-aprovacao'].includes(location.pathname);

  if (loading) {
    return <TravelLoading />;
  }

  const isRootRoute = location.pathname === '/';

  return (
    <>
      {!isRootRoute ? (
        <Layout
          toggleColorMode={toggleColorMode}
          mode={mode}
          showSidebar={shouldShowSidebar}
          hideLogout={shouldHideLogout}
        >
          <Routes>
            <Route path="/" element={currentUser ? <Navigate to="/home" /> : <NewLandingPage />} />
            <Route path="/login" element={!currentUser ? <LoginPage /> : <Navigate to="/home" />} />
            <Route path="/pendente-aprovacao" element={<PendingApprovalPage />} />

            {/* Rotas protegidas */}
            <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
            <Route path="/passageiros" element={<PrivateRoute><PassengerPage /></PrivateRoute>} />
            <Route path="/viagens" element={<PrivateRoute><TravelPage /></PrivateRoute>} />
            <Route path="/viagens/:travelId" element={<PrivateRoute><TravelDetails /></PrivateRoute>} />
            <Route path="/viagens/:travelId/alocar-passageiros" element={<PrivateRoute><PassengerAllocation /></PrivateRoute>} />
            <Route path="/viagens/:travelId/reservas" element={<PrivateRoute><TravelOrderReservationPage /></PrivateRoute>} />
            <Route path="/viagens/:travelId/custos" element={<PrivateRoute><TravelCosts /></PrivateRoute>} />
            <Route path="/veiculos" element={<PrivateRoute><VehiclePage /></PrivateRoute>} />
            <Route path="/veiculos/layout" element={<PrivateRoute><ManageBusLayoutPage /></PrivateRoute>} />
            <Route path="/veiculos/layout/novo" element={<PrivateRoute><BusLayoutBuilderPage /></PrivateRoute>} />
            <Route path="/veiculos/layout/:id" element={<PrivateRoute><BusLayoutBuilderPage /></PrivateRoute>} />
            <Route path="/pagamentos" element={<PrivateRoute><Receivables /></PrivateRoute>} />
            <Route path="/pedidos-e-reservas" element={<PrivateRoute><OrderReservationList /></PrivateRoute>} />
            <Route path="/relatorios" element={<PrivateRoute><ReportPage /></PrivateRoute>} />
            <Route path="/conta" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
            <Route path="/central-ajuda" element={<HelpCenter />} />
            <Route path="/viagens/:travelId/pedido/novo" element={<PrivateRoute><PassengerAllocation isCreating={true} /></PrivateRoute>} />
            <Route path="/viagens/:travelId/pedido/:id" element={<PrivateRoute><PassengerAllocation /></PrivateRoute>} />
            <Route path="/agencia" element={<BusinessRoute> <UpdateAgencyForm /> </BusinessRoute>} />
            {/* {<Route path="/migration-test" element={<MigrationTestPage />} />} */}
            {/* <Route path="/usuarios" element={<PrivateRoute><UserManagement /></PrivateRoute>} /> */}
          </Routes>
        </Layout>
      ) : (
        <Routes>
          <Route path="/" element={currentUser ? <Navigate to="/home" /> : <NewLandingPage />} />
        </Routes>
      )}
    </>
  );
}

export default function App() {
  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(true);

  const LPTheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPTheme : defaultTheme}>
      <CssBaseline enableColorScheme />
      <Router>
        <AuthProvider>
          <DrawerProvider>
            <AuthenticatedApp toggleColorMode={toggleColorMode} mode={mode} />
          </DrawerProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}
