import React, { useState, useEffect } from 'react';
import {
  Container, Box, Card, CardContent, CardActions, Typography,
  Collapse, Button, Switch, Dialog, DialogActions, DialogContent,
  DialogContentText, TextField, DialogTitle, CircularProgress, Tabs, Tab, Paper
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import SecurityIcon from '@mui/icons-material/Security';
import UpdateProfileForm from '../components/account/UpdateProfileForm';
import ChangePasswordForm from '../components/account/ChangePasswordForm';
import SetMasterPasswordForm from '../components/account/SetMasterPasswordForm';
import MyAccountHelp from '../components/helps/MyAccountHelp'
import { getMasterPasswordFullStatus, toggleMasterPasswordActive } from '../services/AuthService';
import { validateMasterPassword } from '../utils/utils';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`account-tabpanel-${index}`}
      aria-labelledby={`account-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `account-tab-${index}`,
    'aria-controls': `account-tabpanel-${index}`,
  };
}

function MyAccount() {
  const [tabValue, setTabValue] = useState(0);
  const [openProfile, setOpenProfile] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [openMasterPassword, setOpenMasterPassword] = useState(false);
  const [masterPasswordActive, setMasterPasswordActive] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [masterPassword, setMasterPassword] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [masterPasswordDefined, setMasterPasswordDefined] = useState(false);

  useEffect(() => {
    const fetchMasterPasswordStatus = async () => {
      const { isActive, isDefined } = await getMasterPasswordFullStatus(); // Ajuste para desestruturar a resposta detalhada
      setMasterPasswordActive(isActive);
      setMasterPasswordDefined(isDefined);
    };
    fetchMasterPasswordStatus();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleToggleMasterPasswordActive = async (event) => {
    const isActive = event.target.checked;

    if (!isActive) {
      if (masterPasswordDefined) {
        setConfirmDialogOpen(true);
      } else {
        await toggleMasterPasswordActive(false);
        setMasterPasswordActive(false);
      }
    } else {
      await toggleMasterPasswordActive(true);
      setMasterPasswordActive(true);
      setMasterPasswordDefined(true); // Considera a senha definida ao ativar
    }
  };

  const handleConfirmToggle = async () => {
    setIsProcessing(true);
    try {
      await validateMasterPassword(masterPassword);
      await toggleMasterPasswordActive(false);
      setMasterPasswordActive(false);
      setMasterPasswordDefined(false); // Reseta a definição da senha
      setConfirmDialogOpen(false);
      setMasterPassword('');
      setError('');
    } catch (error) {
      setError('Senha master incorreta. Tente novamente.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      p: 3
    }}>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%'
        }}>
          <Typography variant="h5" component="h1">
            Minha Conta
          </Typography>
          <MyAccountHelp />
        </Box>
      </Paper>

      <Paper elevation={1} sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="account settings tabs"
            variant="fullWidth"
          >
            <Tab
              icon={<PersonIcon />}
              label="Perfil"
              {...a11yProps(0)}
            />
            <Tab
              icon={<LockIcon />}
              label="Senha"
              {...a11yProps(1)}
            />
            <Tab
              icon={<SecurityIcon />}
              label="Senha Master"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>

        <Box sx={{ minHeight: '400px' }}>
          <TabPanel value={tabValue} index={0}>
            <UpdateProfileForm />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <ChangePasswordForm />
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 3
            }}>
              <Typography variant="h6">
                Senha Master
              </Typography>
              <Switch
                checked={masterPasswordActive}
                onChange={handleToggleMasterPasswordActive}
                color="primary"
              />
            </Box>
            {masterPasswordActive && (
              <SetMasterPasswordForm masterPasswordActive={masterPasswordActive} />
            )}
          </TabPanel>
        </Box>
      </Paper>

      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Desativar Senha Master</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para desativar a senha master, insira a senha master atual.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Senha Master"
            type="password"
            fullWidth
            value={masterPassword}
            onChange={(e) => setMasterPassword(e.target.value)}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDialogOpen(false)}
            variant="outlined"
            disabled={isProcessing}
            sx={{ borderRadius: '50px' }}
          >
            Voltar
          </Button>
          <Button
            onClick={handleConfirmToggle}
            variant="contained"
            color="error"
            disabled={!masterPassword || isProcessing}
            sx={{ color: 'white', borderRadius: '50px' }}
          >
            {isProcessing ? <CircularProgress size={24} /> : 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default MyAccount;
