import React, { useEffect, useState, useContext, createContext } from 'react';
import { auth, db } from '../firebaseConfig';
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    currentUser: null,
    loading: true,
    initialized: false
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      // Se não há usuário, simplemente atualize o estado uma vez
      if (!user) {
        setAuthState({
          currentUser: null,
          loading: false,
          initialized: true
        });
        return;
      }

      try {
        // Faz todas as verificações sem atualizar o estado
        if (!user.emailVerified) {
          await signOut(auth);
          setAuthState({
            currentUser: null,
            loading: false,
            initialized: true
          });
          return;
        }

        // Busca todos os dados necessários
        const [userDoc, agencyDoc, memberDoc] = await Promise.all([
          getDoc(doc(db, 'users', user.uid)),
          user.uid ? getDoc(doc(db, 'agencies', user.uid)) : null,
          user.uid ? getDoc(doc(db, `agencies/${user.uid}/members`, user.uid)) : null
        ]);

        if (!userDoc.exists()) {
          await signOut(auth);
          setAuthState({
            currentUser: null,
            loading: false,
            initialized: true
          });
          return;
        }

        const userData = userDoc.data();

        // Verifica todas as condições necessárias
        const isValid = userData.isApproved &&
          agencyDoc?.exists() &&
          agencyDoc.data().status === 'active' &&
          agencyDoc.data().subscription.status !== 'expired' &&
          memberDoc?.exists();

        if (!isValid) {
          await signOut(auth);
          setAuthState({
            currentUser: null,
            loading: false,
            initialized: true
          });
          return;
        }

        // Se passou por todas as verificações, atualiza o estado uma única vez
        const agencyData = agencyDoc.data();
        const memberData = memberDoc.data();

        setAuthState({
          currentUser: {
            ...user,
            userData: {
              ...userData,
              isApproved: userData.isApproved
            },
            agency: {
              ...agencyData,
              id: userData.agencyId
            },
            role: memberData.roleId || null,
            permissions: memberData.customPermissions || null
          },
          loading: false,
          initialized: true
        });
      } catch (error) {
        console.error('Erro ao verificar autenticação:', error);
        await signOut(auth);
        setAuthState({
          currentUser: null,
          loading: false,
          initialized: true
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const hasPermission = (resource, action) => {
    if (!authState.currentUser) return false;
    if (authState.currentUser.role === 'owner') return true;
    if (authState.currentUser.permissions?.[resource]?.[action]) return true;
    return false;
  };

  return (
    <AuthContext.Provider value={{
      currentUser: authState.currentUser,
      loading: authState.loading,
      initialized: authState.initialized,
      hasPermission,
      isOwner: authState.currentUser?.role === 'owner'
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};