import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './useAuthState';
import TravelLoading from '../components/common/TravelLoading';

export const PrivateRoute = ({ children }) => {
  const { currentUser, loading, initialized } = useAuth();
  const location = useLocation();

  if (!initialized || loading) {
    return <TravelLoading />;
  }

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!currentUser.userData?.isApproved) {
    return <Navigate to="/pendente-aprovacao" replace />;
  }

  return children;
};