import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Typography, Button, Box, CircularProgress, Snackbar, Alert, Modal, TextField, Dialog, DialogActions, Tooltip, DialogContent, DialogContentText, DialogTitle, Pagination, MenuItem, Select, FormControl, InputLabel, InputAdornment, IconButton, Grid, Collapse } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import VehicleDetails from '../components/vehicles/VehicleDetails';
import VehicleCard from '../components/vehicles/VehicleCard';
import VehicleForm from '../components/vehicles/VehicleForm';
import VehiclePageHelp from '../components/helps/VehiclePageHelp';
import TravelLoading from '../components/common/TravelLoading';
import { addVehicle, getVehiclesPaginated, updateVehicle, deleteVehicle } from '../services/VehicleService';
import { validateMasterPassword } from '../utils/utils';
import { getMasterPasswordStatus } from '../services/AuthService';

const VehiclePage = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openModal, setOpenModal] = useState(false);
  const [editingVehicle, setEditingVehicle] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [vehicleToDelete, setVehicleToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [masterPassword, setMasterPassword] = useState('');
  const [showMasterPassword, setShowMasterPassword] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [masterPasswordActive, setMasterPasswordActive] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastDocument, setLastDocument] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [tempSearchTerm, setTempSearchTerm] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    fetchInitialData();
    checkMasterPasswordStatus();
  }, []);

  const checkMasterPasswordStatus = async () => {
    const status = await getMasterPasswordStatus();
    setMasterPasswordActive(status.isActive);
  };

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      // Only fetch vehicles now, layouts are embedded
      await fetchVehiclesPage();
    } catch (err) {
      setError('Erro ao buscar veículos');
      setSnackbarMessage('Erro ao buscar veículos');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchVehiclesPage = async (resetData = false) => {
    try {
      const filterOptions = {
        searchTerm: searchTerm,
        sortOrder: sortOrder
      };

      if (resetData) {
        setCurrentPage(1);
        setLastDocument(null);
      }

      const result = await getVehiclesPaginated(
        itemsPerPage,
        resetData ? null : lastDocument,
        filterOptions
      );

      setVehicles(result.vehicles);
      setLastDocument(result.lastVisible);
      setHasMore(result.hasMore);
      setTotalCount(result.totalCount);
    } catch (err) {
      setError('Erro ao buscar veículos');
      setSnackbarMessage('Erro ao buscar veículos');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Update search handler to reset pagination
  const handleSearchChange = (event) => {
    setTempSearchTerm(event.target.value);
  };

  const executeSearch = async () => {
    setSearchLoading(true);
    try {
      setSearchTerm(tempSearchTerm);
      setCurrentPage(1);
      setLastDocument(null);
      const filterOptions = {
        searchTerm: tempSearchTerm, // Use tempSearchTerm aqui ao invés de searchTerm
        sortOrder: sortOrder
      };

      const result = await getVehiclesPaginated(
        itemsPerPage,
        null,
        filterOptions
      );

      setVehicles(result.vehicles);
      setLastDocument(result.lastVisible);
      setHasMore(result.hasMore);
      setTotalCount(result.totalCount);
    } finally {
      setSearchLoading(false);
    }
  };

  const clearSearch = async () => {
    setSearchLoading(true);
    try {
      setTempSearchTerm('');
      setSearchTerm('');
      setCurrentPage(1);
      setLastDocument(null);
      const filterOptions = {
        searchTerm: '',
        sortOrder: sortOrder
      };

      const result = await getVehiclesPaginated(
        itemsPerPage,
        null,
        filterOptions
      );

      setVehicles(result.vehicles);
      setLastDocument(result.lastVisible);
      setHasMore(result.hasMore);
      setTotalCount(result.totalCount);
    } finally {
      setSearchLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      executeSearch();
    }
  };

  // Update page change handler
  const handlePageChange = async (event, value) => {
    // Se a página mudou
    if (value !== currentPage) {
      setLoading(true);
      try {
        // Se voltando para página anterior
        if (value < currentPage) {
          // Reset para primeira página e navega até a página desejada
          let lastDoc = null;
          for (let i = 1; i < value; i++) {
            const result = await getVehiclesPaginated(itemsPerPage, lastDoc, {
              searchTerm,
              sortOrder
            });
            lastDoc = result.lastVisible;
          }
          const finalResult = await getVehiclesPaginated(itemsPerPage, lastDoc, {
            searchTerm,
            sortOrder
          });
          setVehicles(finalResult.vehicles);
          setLastDocument(finalResult.lastVisible);
          setHasMore(finalResult.hasMore);
        } else {
          // Avançando para próxima página
          const result = await getVehiclesPaginated(itemsPerPage, lastDocument, {
            searchTerm,
            sortOrder
          });
          setVehicles(result.vehicles);
          setLastDocument(result.lastVisible);
          setHasMore(result.hasMore);
        }
        setCurrentPage(value);
      } catch (error) {
        setError('Erro ao mudar de página');
        setSnackbarMessage('Erro ao mudar de página');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
        setLoading(false);
        setLoading(false);
      }
    }
  };

  const handleAddVehicle = useCallback(async (vehicle) => {
    try {
      await addVehicle(vehicle);
      fetchVehiclesPage(true);
      setSnackbarMessage('Veículo adicionado com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setOpenModal(false);
    } catch (err) {
      setError('Erro ao adicionar veículo');
      setSnackbarMessage('Erro ao adicionar veículo');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [fetchVehiclesPage]);

  const handleEditVehicle = useCallback(async (vehicle) => {
    try {
      await updateVehicle(vehicle.id, vehicle);
      fetchVehiclesPage(true);
      setSnackbarMessage('Veículo atualizado com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setOpenModal(false);
      setEditingVehicle(null);
    } catch (err) {
      setError('Erro ao atualizar veículo');
      setSnackbarMessage('Erro ao atualizar veículo');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [fetchVehiclesPage]);

  const handleDeleteVehicle = useCallback(async () => {
    setLoading(true);
    try {
      if (masterPasswordActive) {
        await validateMasterPassword(masterPassword);
      }
      await deleteVehicle(vehicleToDelete.id);
      fetchVehiclesPage(true);
      setSnackbarMessage('Veículo excluído com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setConfirmDeleteOpen(false);
      setVehicleToDelete(null);
      setMasterPassword('');
    } catch (err) {
      setError('Erro ao excluir veículo');
      setSnackbarMessage('Erro ao excluir veículo: ' + err.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  }, [masterPasswordActive, masterPassword, vehicleToDelete, fetchVehiclesPage]);

  const startEditing = (vehicle) => {
    setEditingVehicle(vehicle);
    setOpenModal(true);
  };

  const openConfirmDeleteDialog = (vehicle) => {
    setVehicleToDelete(vehicle);
    setConfirmDeleteOpen(true);
  };

  const closeConfirmDeleteDialog = () => {
    setConfirmDeleteOpen(false);
    setVehicleToDelete(null);
    setMasterPassword('');
  };

  const handleCancel = () => {
    setOpenModal(false);
    setEditingVehicle(null);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setCurrentPage(1);
    setLastDocument(null);
    fetchVehiclesPage(true);
  };

  const handleCardClick = useCallback((vehicle) => {
    setSelectedVehicle(vehicle);
  }, []);

  const handleClickShowMasterPassword = () => {
    setShowMasterPassword(!showMasterPassword);
  };

  return (
    <Box>
      {error && (
        <Snackbar open autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
        <Typography variant="h6" component="div">
          Veículos
          <Box component="span" sx={{ ml: 1 }}>
            <VehiclePageHelp />
          </Box>
        </Typography>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setOpenModal(true)} sx={{ borderRadius: '50px' }}>
          Adicionar
        </Button>
        <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} onClick={() => setFiltersVisible(!filtersVisible)} sx={{ borderRadius: '50px' }}>
          {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
        </Button>
      </Box>

      <Collapse in={filtersVisible}>
        <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
          <FormControl variant="outlined" sx={{ minWidth: 200 }}>
            <InputLabel id="sort-order-label">Ordenar por</InputLabel>
            <Select
              labelId="sort-order-label"
              value={sortOrder}
              onChange={handleSortChange}
              label="Ordenar por Nome"
              disabled={searchLoading}
            >
              <MenuItem value="asc">Ordem Alfabética</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ flexGrow: 1, minWidth: '240px', position: 'relative' }}>
            <TextField
              fullWidth
              label="Busca"
              variant="outlined"
              value={tempSearchTerm}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
              placeholder="Digite qualquer informação para buscar"
              disabled={searchLoading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {tempSearchTerm && (
                      <Tooltip title="Limpar">
                        <IconButton onClick={clearSearch} disabled={searchLoading} size="small">
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Buscar">
                      <IconButton
                        onClick={executeSearch}
                        sx={{ ml: tempSearchTerm ? 1 : 0 }}
                        disabled={searchLoading}
                        size="small"
                      >
                        {searchLoading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          <SearchIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />
          </Box>
        </Box>
      </Collapse>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <TravelLoading disableAlignItems />
        </Box>
      ) : (
        <>
          <Grid container spacing={2}>
            {vehicles.length > 0 ? (
              vehicles.map((vehicle) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={vehicle.id}>
                  <VehicleCard
                    vehicle={vehicle}  // o layout já está dentro de vehicle como vehicle.layout
                    onEdit={startEditing}
                    onDelete={openConfirmDeleteDialog}
                    onCardClick={handleCardClick}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="body1" align="center">
                  Nenhum veículo encontrado.
                </Typography>
              </Grid>
            )}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              count={Math.ceil(totalCount / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              showFirstButton
              showLastButton
              disabled={loading}
            />
          </Box>
        </>
      )}
      <Modal open={openModal} onClose={handleCancel}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '20px', // Adicione esta linha para bordas arredondadas
            overflow: 'hidden', // Para garantir que o conteúdo não saia do Box arredondado
          }}
        >
          <VehicleForm onSave={editingVehicle ? handleEditVehicle : handleAddVehicle} initialVehicle={editingVehicle} onCancel={handleCancel} fetchVehicles={fetchVehiclesPage} />
        </Box>
      </Modal>
      <Dialog open={confirmDeleteOpen} onClose={closeConfirmDeleteDialog}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir o veículo {vehicleToDelete?.identificadorVeiculo}? Esta ação não pode ser desfeita.
          </DialogContentText>
          {masterPasswordActive && (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="masterPassword"
              label="Senha Master"
              type={showMasterPassword ? 'text' : 'password'}
              id="masterPassword"
              value={masterPassword}
              onChange={(e) => setMasterPassword(e.target.value)}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowMasterPassword}
                      edge="end"
                    >
                      {showMasterPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDeleteDialog} variant="outlined" disabled={loading} sx={{ borderRadius: '50px' }} >
            Voltar
          </Button>
          <Button
            onClick={handleDeleteVehicle}
            variant="contained"
            color="error"
            disabled={masterPasswordActive && !masterPassword || loading}
            sx={{ color: 'white', borderRadius: '50px' }}
          >
            {loading ? <CircularProgress size={24} /> : 'Excluir'}
          </Button>
        </DialogActions>
      </Dialog>
      {selectedVehicle && (
        <VehicleDetails
          vehicle={selectedVehicle}
          open={Boolean(selectedVehicle)}
          onClose={() => setSelectedVehicle(null)}
        />
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VehiclePage;
