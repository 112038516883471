import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { formatCPF, formatTelefone, formatDate, formatDateForFilename } from './utils';
import jsPDF from 'jspdf';
import logo from '../assets/logo-travell-combr.png';
import 'jspdf-autotable';

export const exportToPDF = (travel, reservations, passengers, selectedFields, sortOrder) => {
  const doc = new jsPDF('landscape', 'pt');

  const truncateText = (text, maxLength) => {
    if (!text) return 'Não informado';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const columns = [
    { title: "Assento", dataKey: "numeroAssento" },
    { title: "Nome do Passageiro", dataKey: "passengerNome" },
    { title: "Documentos", dataKey: "passengerDocumentos" },
    { title: "Data Nascimento", dataKey: "dataNascimento" },
    { title: "Endereço", dataKey: "passengerEndereco" },
    { title: "Telefone", dataKey: "passengerTelefone" },
    { title: "Informações Adicionais", dataKey: "informacoesAdicionais" }
  ];

  const filteredColumns = columns.filter(col => selectedFields[col.dataKey]);

  const sortedReservations = reservations
  .filter(reservation => reservation.status !== 'Cancelada')
  .sort((a, b) => {
    if (sortOrder === "numeroAssento") {
      return (a.numeroAssento || 0) - (b.numeroAssento || 0);
    }
    if (sortOrder === "passengerNome") {
      const passengerA = passengers.find(p => p.id === a.passengerId)?.nome || "";
      const passengerB = passengers.find(p => p.id === b.passengerId)?.nome || "";
      return passengerA.localeCompare(passengerB);
    }
    return 0;
  });

  const rows = sortedReservations.map(reservation => {
    const passenger = passengers.find(p => p.id === reservation.passengerId) || {};
    const documentos = passenger.estrangeiro ?
      `Passaporte: ${passenger.passaporte || 'Não informado'}` :
      `CPF: ${passenger.cpf ? formatCPF(passenger.cpf) : 'Não informado'} / RG: ${passenger.rg || 'Não informado'}`;
    const menorDeIdade = passenger.menorDeIdade ? 'Menor de Idade' : '';

    return {
      numeroAssento: reservation.numeroAssento || 'Ocupante de Colo',
      passengerNome: passenger.nome || 'Não informado',
      passengerDocumentos: documentos.trim(),
      dataNascimento: formatDate(passenger.dataNascimento),
      passengerEndereco: truncateText(passenger.endereco, 200),
      passengerTelefone: formatTelefone(passenger.telefone),
      informacoesAdicionais: truncateText(`${menorDeIdade} ${reservation.detalhesPagamento?.informacoesAdicionais || ''}`, 50)
    };
  });

  const img = new Image();
  img.src = logo;

  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;

  img.onload = () => {
    const logoX = pageWidth - 190;
    const logoY = 20;
    const logoWidth = 165;
    const logoHeight = 55;

    // Adiciona a logo
    doc.addImage(img, 'PNG', logoX, logoY, logoWidth, logoHeight);

    // Torna a logo clicável
    doc.link(logoX, logoY, logoWidth, logoHeight, { url: 'https://travell.com.br' });

    // Adiciona informações da viagem
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text('Detalhes da Viagem', 40, 50);

    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text(`Identificador: ${travel?.identificador || 'Não informado'}`, 40, 70);
    doc.text(`Origem: ${travel?.origem || 'Não informado'}`, 40, 85);
    doc.text(`Destino: ${travel?.destino || 'Não informado'}`, 40, 100);
    doc.text(`Data de Ida: ${formatDate(travel?.dataIda) || 'Não informado'} às ${travel?.horarioIda || 'Não informado'}`, 40, 115);

    if (travel?.somenteIda) {
      doc.text(`Somente Ida`, 40, 130);
    } else {
      doc.text(`Data de Retorno: ${formatDate(travel?.dataRetorno) || 'Não informado'} às ${travel?.horarioRetorno || 'Não informado'}`, 40, 130);
    }

    if (travel?.veiculo) {
      doc.text(`Veículo: ${travel.veiculo.identificadorVeiculo || 'Não informado'} - ${travel.veiculo.placa || 'Não informado'} (${travel.veiculo.empresa || 'Não informado'})`, 40, 145);
    }
    if (travel?.informacoesAdicionais) {
      doc.text(`Informações Adicionais: ${travel.informacoesAdicionais}`, 40, 160);
    }

    // Cria a tabela
    doc.autoTable({
      head: [filteredColumns.map(col => col.title)],
      body: rows.map(row => filteredColumns.map(col => row[col.dataKey])),
      startY: 180,
      theme: 'striped',
      margin: { top: 10, bottom: 30, left: 20, right: 20 },
      styles: { fontSize: 10, halign: 'center' },
      headStyles: { fillColor: [25, 118, 210], textColor: [255, 255, 255] },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      didDrawPage: function (data) {
        doc.setFontSize(10);
        doc.setTextColor(40);

        let str = 'Página ' + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' de ' + doc.internal.getNumberOfPages();
        }
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });

    // Define o nome do arquivo e salva
    const identifier = travel?.identificador || 'sem-identificador';
    const origem = travel?.origem || 'sem-origem';
    const destino = travel?.destino || 'sem-destino';
    const dataIda = formatDateForFilename(travel?.dataIda || 'sem-data-ida');
    const dataRetorno = travel?.somenteIda ? 'somente-ida' : formatDateForFilename(travel?.dataRetorno || 'sem-data-retorno');
    const filename = `viagem_${identifier}_de_${origem}_na_data_${dataIda}_a_${destino}_com_volta_${dataRetorno}.pdf`;

    doc.save(filename);
  };
};
