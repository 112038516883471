import React, { useState, useEffect } from 'react';
import { Grid, TextField, MenuItem, InputAdornment, Typography, Box, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PaymentSummary from './PaymentSummary';
import PaymentDetailsFormHelp from '../helps/PaymentDetailsFormHelp';
import { formatCPF, unformatCPF } from '../../utils/utils';
import { useCurrencyInput, currencyNames } from '../../utils/formatCurrency';

const PaymentDetailsForm = ({ detalhesPagamento, handlePaymentDetailChange, errors, validatePaymentField }) => {
  const theme = useTheme();
  const [pagadorEstrangeiro, setPagadorEstrangeiro] = useState(!!detalhesPagamento.passaportePagador);
  const [manualChange, setManualChange] = useState(false);

  const initialValue = detalhesPagamento.valorTotal != null ?
    String(detalhesPagamento.valorTotal).replace('.', ',') :
    '0,00';

  const {
    value: valorTotalTemp,
    handleChange: handleValorTotalChange,
    handleBlur: handleValorTotalBlur,
    toNumber: getValorTotalNumber,
    setValue: setValorTotalTemp
  } = useCurrencyInput(initialValue);

  useEffect(() => {
    if (detalhesPagamento.valorTotal != null) {
      const formattedValue = detalhesPagamento.valorTotal.replace('.', ',');
      setValorTotalTemp(formattedValue);
    }
  }, [detalhesPagamento.valorTotal]);

  useEffect(() => {
    if (!manualChange) {
      setPagadorEstrangeiro(!!detalhesPagamento.passaportePagador);
    }
  }, [detalhesPagamento, manualChange]);

  const handlePagadorChange = (e) => {
    setManualChange(true);
    const isEstrangeiro = e.target.value === 'estrangeiro';
    setPagadorEstrangeiro(isEstrangeiro);

    if (isEstrangeiro) {
      handlePaymentDetailChange('cpfPagador', '');
      handlePaymentDetailChange('rgPagador', '');
    } else {
      handlePaymentDetailChange('passaportePagador', '');
    }
  };

  return (
    <Box
      sx={{
        mb: 3,
        p: 2,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" sx={{ color: theme.palette.text.primary, marginRight: 1 }}>
          Informações de Pagamento do Pedido
        </Typography>
        <PaymentDetailsFormHelp />
      </Box>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <RadioGroup
          row
          value={pagadorEstrangeiro ? 'estrangeiro' : 'brasileiro'}
          onChange={handlePagadorChange}
        >
          <FormControlLabel
            value="brasileiro"
            control={<Radio sx={{ color: theme.palette.primary.main }} />}
            label="Pagador Brasileiro"
            sx={{ color: theme.palette.text.primary }}
          />
          <FormControlLabel
            value="estrangeiro"
            control={<Radio sx={{ color: theme.palette.primary.main }} />}
            label="Pagador Estrangeiro"
            sx={{ color: theme.palette.text.primary }}
          />
        </RadioGroup>
      </FormControl>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Nome Completo do Pagador"
            name="nomePagador"
            value={detalhesPagamento.nomePagador || ''}
            onChange={(e) => handlePaymentDetailChange('nomePagador', e.target.value)}
            onBlur={(e) => validatePaymentField('nomePagador', e.target.value)}
            error={!!errors['nomePagador']}
            helperText={errors['nomePagador']}
            fullWidth
            required
            inputProps={{ maxLength: 255 }}
            sx={{ backgroundColor: theme.palette.background.default, borderRadius: '4px' }}
          />
        </Grid>

        {!pagadorEstrangeiro ? (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                label="CPF do Pagador"
                name="cpfPagador"
                value={formatCPF(detalhesPagamento.cpfPagador || '')}
                onChange={(e) => handlePaymentDetailChange('cpfPagador', unformatCPF(e.target.value))}
                onBlur={(e) => validatePaymentField('cpfPagador', e.target.value)}
                error={!!errors['cpfPagador']}
                helperText={errors['cpfPagador']}
                fullWidth
                required
                inputProps={{ maxLength: 14 }}
                sx={{ backgroundColor: theme.palette.background.default, borderRadius: '4px' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="RG do Pagador"
                name="rgPagador"
                value={detalhesPagamento.rgPagador || ''}
                onChange={(e) => handlePaymentDetailChange('rgPagador', e.target.value)}
                onBlur={(e) => validatePaymentField('rgPagador', e.target.value)}
                error={!!errors['rgPagador']}
                helperText={errors['rgPagador']}
                fullWidth
                required
                inputProps={{ maxLength: 20 }}
                sx={{ backgroundColor: theme.palette.background.default, borderRadius: '4px' }}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} md={6}>
            <TextField
              label="Passaporte do Pagador"
              name="passaportePagador"
              value={detalhesPagamento.passaportePagador || ''}
              onChange={(e) => handlePaymentDetailChange('passaportePagador', e.target.value)}
              onBlur={(e) => validatePaymentField('passaportePagador', e.target.value)}
              error={!!errors['passaportePagador']}
              helperText={errors['passaportePagador']}
              fullWidth
              required
              inputProps={{ maxLength: 20 }}
              sx={{ backgroundColor: theme.palette.background.default, borderRadius: '4px' }}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <TextField
            label="Valor Total do Pedido"
            name="valorTotal"
            value={valorTotalTemp}
            onChange={handleValorTotalChange}
            onBlur={() => {
              handleValorTotalBlur();
              const numericValue = getValorTotalNumber();
              const formattedValueForDisplay = numericValue.toFixed(2).replace('.', ',');
              const formattedValueForDB = numericValue.toFixed(2);

              handlePaymentDetailChange('valorTotal', formattedValueForDB);
              validatePaymentField('valorTotal', formattedValueForDB);
              setValorTotalTemp(formattedValueForDisplay);
            }}
            error={!!errors['valorTotal']}
            helperText={errors['valorTotal']}
            fullWidth
            required
            inputProps={{
              inputMode: 'numeric'
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currencyNames['BRL'].symbol}
                </InputAdornment>
              ),
            }}
            sx={{ backgroundColor: theme.palette.background.default, borderRadius: '4px' }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            select
            label="Método de Pagamento do Pedido"
            name="metodoPagamento"
            value={detalhesPagamento.metodoPagamento || ''}
            onChange={(e) => handlePaymentDetailChange('metodoPagamento', e.target.value)}
            onBlur={(e) => validatePaymentField('metodoPagamento', e.target.value)}
            error={!!errors['metodoPagamento']}
            helperText={errors['metodoPagamento']}
            fullWidth
            required
            sx={{ backgroundColor: theme.palette.background.default, borderRadius: '4px' }}
          >
            <MenuItem value="Dinheiro">Dinheiro</MenuItem>
            <MenuItem value="Pix">Pix</MenuItem>
            <MenuItem value="Cartão">Cartão</MenuItem>
            <MenuItem value="Boleto">Boleto</MenuItem>
            <MenuItem value="Outro">Outro</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Informações Adicionais do Pedido"
            name="informacoesAdicionais"
            value={detalhesPagamento.informacoesAdicionais || ''}
            onChange={(e) => handlePaymentDetailChange('informacoesAdicionais', e.target.value)}
            onBlur={(e) => validatePaymentField('informacoesAdicionais', e.target.value)}
            error={!!errors['informacoesAdicionais']}
            helperText={errors['informacoesAdicionais']}
            fullWidth
            multiline
            maxRows={4}
            inputProps={{ maxLength: 255 }}
            sx={{ backgroundColor: theme.palette.background.default, borderRadius: '4px' }}
          />
        </Grid>
      </Grid>

      <PaymentSummary
        valorPago={detalhesPagamento.valorPago}
        valorRestante={detalhesPagamento.valorRestante}
      />
    </Box>
  );
};

export default PaymentDetailsForm;