import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

// Configurações do Firebase baseadas no ambiente
const getFirebaseConfig = () => {

  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    return {
      apiKey: process.env.REACT_APP_DEV_API_KEY,
      authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_DEV_PROJECT_ID,
      storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_DEV_APP_ID
    };
  }

  return {
    apiKey: process.env.REACT_APP_PROD_API_KEY,
    authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROD_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_PROD_APP_ID
  };
};

const firebaseConfig = getFirebaseConfig();

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Conecta aos emuladores se estiver em desenvolvimento
if (process.env.REACT_APP_USE_EMULATOR === 'true') {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
}

export { auth, db };
export default app;