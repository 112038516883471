import { alpha } from '@mui/material/styles';
import { gray, orange } from '../themePrimitives';

export const feedbackCustomizations = {
  MuiAlert: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 10,
        '&.MuiAlert-standardSuccess': {
          backgroundColor: 'rgb(237, 247, 237)',
          color: 'rgb(30, 70, 32)',
          border: `1px solid ${alpha('#2e7d32', 0.5)}`,
          '& .MuiAlert-icon': {
            color: '#2e7d32'
          }
        },
        '&.MuiAlert-standardError': {
          backgroundColor: 'rgb(253, 237, 237)',
          color: 'rgb(95, 33, 32)',
          border: `1px solid ${alpha('#d32f2f', 0.5)}`,
          '& .MuiAlert-icon': {
            color: '#d32f2f'
          }
        },
        '&.MuiAlert-standardWarning': {
          backgroundColor: orange[100],
          color: theme.palette.text.primary,
          border: `1px solid ${alpha(orange[300], 0.5)}`,
          '& .MuiAlert-icon': {
            color: orange[500]
          }
        },
        '&.MuiAlert-standardInfo': {
          backgroundColor: 'rgb(229, 246, 253)',
          color: 'rgb(1, 67, 97)',
          border: `1px solid ${alpha('#0288d1', 0.5)}`,
          '& .MuiAlert-icon': {
            color: '#0288d1'
          }
        },
        ...theme.applyStyles('dark', {
          '&.MuiAlert-standardSuccess': {
            backgroundColor: alpha('#2e7d32', 0.2),
            border: `1px solid ${alpha('#2e7d32', 0.5)}`
          },
          '&.MuiAlert-standardError': {
            backgroundColor: alpha('#d32f2f', 0.2),
            border: `1px solid ${alpha('#d32f2f', 0.5)}`
          },
          '&.MuiAlert-standardWarning': {
            backgroundColor: `${alpha(orange[900], 0.5)}`,
            border: `1px solid ${alpha(orange[800], 0.5)}`
          },
          '&.MuiAlert-standardInfo': {
            backgroundColor: alpha('#0288d1', 0.2),
            border: `1px solid ${alpha('#0288d1', 0.5)}`
          }
        })
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          border: '1px solid',
          borderColor: theme.palette.divider,
        },
      }),
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: 8,
        borderRadius: 8,
        backgroundColor: gray[200],
        ...theme.applyStyles('dark', {
          backgroundColor: gray[800],
        }),
      }),
    },
  },
};
