import { db, auth } from '../firebaseConfig';
import { collection, doc, addDoc, getDocs, updateDoc, getDoc, query, where } from 'firebase/firestore';

const getAgencyRef = async () => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  return doc(db, 'agencies', userDoc.data().agencyId);
};

export const getCostsByTravelId = async (travelId) => {
  const agencyRef = await getAgencyRef();
  const costsCollectionRef = collection(agencyRef, 'costs');
  
  const activeCostsQuery = query(
    costsCollectionRef,
    where('travelId', '==', travelId),
    where('estaAtivo', '==', true)
  );
  
  const snapshot = await getDocs(activeCostsQuery);
  
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

export const addCost = async (travelId, costData) => {
  const agencyRef = await getAgencyRef();
  const costsCollectionRef = collection(agencyRef, 'costs');
  
  const timestamp = new Date().toISOString();
  const costWithMetadata = {
    travelId,
    description: costData.description,
    amount: costData.amount,
    date: costData.date,
    type: costData.type,
    paymentMethod: costData.paymentMethod,
    estaAtivo: true,
    metadata: {
      createdAt: timestamp,
      updatedAt: timestamp,
      deletedAt: null
    }
  };
  
  const costDoc = await addDoc(costsCollectionRef, costWithMetadata);
  return { id: costDoc.id, ...costWithMetadata };
};

export const updateCost = async (travelId, costId, updatedCost) => {
  try {
    const agencyRef = await getAgencyRef();
    const costDocRef = doc(agencyRef, 'costs', costId);
    
    const costDoc = await getDoc(costDocRef);
    if (!costDoc.exists()) {
      throw new Error('Document not found');
    }
    
    const currentData = costDoc.data();
    const timestamp = new Date().toISOString();
    
    const costWithUpdatedMetadata = {
      travelId,
      description: updatedCost.description,
      amount: updatedCost.amount,
      date: updatedCost.date,
      type: updatedCost.type,
      paymentMethod: updatedCost.paymentMethod,
      estaAtivo: true,
      metadata: {
        ...currentData.metadata,
        updatedAt: timestamp
      }
    };
    
    await updateDoc(costDocRef, costWithUpdatedMetadata);
    return { id: costId, ...costWithUpdatedMetadata };
  } catch (error) {
    console.error('Error in updateCost:', error);
    throw error;
  }
};

export const deleteCost = async (travelId, costId) => {
  const agencyRef = await getAgencyRef();
  const costDocRef = doc(agencyRef, 'costs', costId);
  
  const costDoc = await getDoc(costDocRef);
  const currentData = costDoc.data();
  
  const timestamp = new Date().toISOString();
  const deletionUpdate = {
    estaAtivo: false,
    metadata: {
      ...currentData.metadata, // Preserve existing metadata
      deletedAt: timestamp,
      updatedAt: timestamp
    }
  };
  
  await updateDoc(costDocRef, deletionUpdate);
  return costId;
};