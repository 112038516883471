import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography, Button, Box, Grid, CircularProgress, Snackbar, Alert, Collapse, FormControl, InputLabel, Select, MenuItem, TextField, Pagination, InputAdornment
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import LayoutCard from '../components/seatlayout/LayoutCard';
import ManageBusLayoutPageHelp from '../components/helps/ManageBusLayoutPageHelp';
import TravelLoading from '../components/common/TravelLoading';
import { getPaginatedLayouts, deleteLayout } from '../services/LayoutService';
import { useNavigate } from 'react-router-dom';

const ManageBusLayoutPage = () => {
  const [layouts, setLayouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [totalItems, setTotalItems] = useState(0);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [allLayouts, setAllLayouts] = useState([]);
  const navigate = useNavigate()

  // ManageBusLayoutPage.js
  const fetchLayouts = useCallback(async (page = 1) => {
    setLoading(true);
    try {
      const startAfterDoc = page > 1 ? lastDoc : null;
      const result = await getPaginatedLayouts(
        itemsPerPage,
        startAfterDoc,
        sortOrder,
        searchTerm.toLowerCase(),
        page
      );

      setLayouts(result.layouts);
      setTotalItems(result.total);
      setLastDoc(result.lastDoc);
      setHasMore(result.hasMore);
    } catch (err) {
      console.error('Error in fetchLayouts:', err);
      setSnackbarMessage('Erro ao buscar layouts');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  }, [itemsPerPage, sortOrder, searchTerm, lastDoc]);

  useEffect(() => {
    fetchLayouts(1);
  }, [sortOrder, searchTerm]);

  const handleDeleteLayout = async (layoutId) => {
    setLoading(true);
    try {
      await deleteLayout(layoutId);
      fetchLayouts(currentPage);
      setSnackbarMessage('Layout excluído com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      setSnackbarMessage('Erro ao excluir layout');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const goToLayoutBuilder = (layout = null) => {
    if (layout) {
      navigate(`/veiculos/layout/${layout.id}`, { state: { layout } });
    } else {
      navigate('/veiculos/layout/novo');
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const executeSearch = () => {
    setSearchTerm(searchInput);
    setCurrentPage(1);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      executeSearch();
    }
  };

  const handleClearSearch = () => {
    setSearchInput('');
    setSearchTerm('');
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchLayouts(value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setCurrentPage(1);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
        <Typography variant="h6" component="div">
          Layout de Veículos
          <Box component="span" sx={{ ml: 1 }}> {/* Usando Box para adicionar margem */}
            <ManageBusLayoutPageHelp />
          </Box>
        </Typography>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => goToLayoutBuilder()} sx={{ borderRadius: '50px' }}>
          Adicionar Novo Layout
        </Button>
        <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} onClick={() => setFiltersVisible(!filtersVisible)} sx={{ borderRadius: '50px' }}>
          {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
        </Button>
      </Box>

      {/* Filtros */}
      <Collapse in={filtersVisible}>
        <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
          <FormControl variant="outlined" sx={{ minWidth: 200 }}>
            <InputLabel id="sort-order-label">Ordenar por</InputLabel>
            <Select
              labelId="sort-order-label"
              value={sortOrder}
              onChange={handleSortChange}
              label="Ordenar por Nome"
            >
              <MenuItem value="asc">Ordem Alfabética</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Buscar"
            variant="outlined"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Buscar por ID ou Nome"
            sx={{ flexGrow: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchInput && (
                    <IconButton
                      onClick={handleClearSearch}
                      edge="end"
                      aria-label="limpar busca"
                      size="small"
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={executeSearch}
                    edge="end"
                    aria-label="buscar layouts"
                    size="small"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Collapse>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <TravelLoading disableAlignItems />
        </Box>
      ) : (
        <>
          <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {layouts.length > 0 ? (
              layouts.map((layout) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={layout.id}>
                  <LayoutCard
                    layout={layout}
                    totalSeats={layout.assentosTotais}
                    isTwoFloors={layout.doisAndares}
                    onEdit={() => goToLayoutBuilder(layout)}
                    onDelete={handleDeleteLayout}
                    sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1" align="center">Nenhum layout encontrado.</Typography>
              </Grid>
            )}
          </Grid>

          {/* Paginação */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              count={Math.ceil(totalItems / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              showFirstButton
              showLastButton
            />
          </Box>
        </>
      )}

      {/* Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ManageBusLayoutPage;
