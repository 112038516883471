import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, MenuItem, TextField, InputAdornment, Collapse, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Edit, Delete, Save, Cancel, Search, FilterList as FilterListIcon, MonetizationOn, Payment } from '@mui/icons-material';
import { formatDate } from '../../utils/utils';
import { useCurrencyInput, currencyNames } from '../../utils/formatCurrency';

const ITEMS_PER_PAGE = 10;
const DESCRIPTION_MAX_LENGTH = 20;

const CostList = ({
  costs,
  editingCost,
  setEditingCost,
  handleUpdateCost,
  handleCancelEdit,
  handleDeleteCost,
  handleEditClick,
  transactionTypes,
  paymentMethods,
  totalOrderReceived = 0,
  totalOrderReceivable = 0
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterPaymentMethod, setFilterPaymentMethod] = useState('');
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedCost, setSelectedCost] = useState(null);
  const {
    value: editingAmount,
    handleChange: handleAmountChange,
    handleBlur: handleAmountBlur,
    toNumber: getAmountNumber,
    setValue: setEditingAmount
  } = useCurrencyInput('0');
  const theme = useTheme();

  useEffect(() => {
    if (editingCost) {
      // Formate o valor inicial corretamente
      const formatted = parseFloat(editingCost.amount).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      setEditingAmount(formatted);
    }
  }, [editingCost?.id]);

  useEffect(() => {
    if (editingCost) {
      setEditingCost(prev => ({
        ...prev,
        amount: getAmountNumber()
      }));
    }
  }, [editingAmount]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const applyFilters = () => {
    return costs
      .filter((cost) => cost.description.toLowerCase().includes(searchTerm.toLowerCase()))
      .filter((cost) => (filterType ? cost.type === filterType : true))
      .filter((cost) => (filterPaymentMethod ? cost.paymentMethod === filterPaymentMethod : true));
  };

  const filteredCosts = applyFilters();

  // Separar custos e recebimentos
  const filteredExpenses = filteredCosts.filter((cost) => cost.type === 'Custo');
  const filteredIncomes = filteredCosts.filter((cost) => cost.type === 'Recebimento');

  // Cálculos de totais
  const totalExpenses = filteredExpenses.reduce((sum, cost) => sum + parseFloat(cost.amount || 0), 0);
  const totalIncomes = filteredIncomes.reduce((sum, cost) => sum + parseFloat(cost.amount || 0), 0);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentCosts = filteredCosts.slice(startIndex, endIndex);

  // Função para abrir o modal de confirmação de exclusão
  const handleOpenDeleteDialog = (cost) => {
    setSelectedCost(cost);
    setDeleteDialogOpen(true);
  };

  // Função para fechar o modal de confirmação de exclusão
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedCost(null);
  };

  // Função para confirmar a exclusão após a confirmação no modal
  const confirmDeleteCost = () => {
    if (selectedCost) {
      handleDeleteCost(selectedCost.id);
    }
    handleCloseDeleteDialog();
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Paper elevation={2} sx={{ p: 2, mb: 3, borderRadius: '8px', backgroundColor: theme.palette.background.default }}>
        <Typography variant="subtitle1" gutterBottom>
          Valores referentes aos pedidos da viagem
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 1 }}>
          <Tooltip title="Valor ainda a receber dos pedidos" arrow>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Payment sx={{ fontSize: 20, color: theme.palette.info.main }} />
              <Typography variant="body2" color={theme.palette.text.primary}>
                <strong>A Receber:</strong> R$ {totalOrderReceivable.toFixed(2)}
              </Typography>
            </Box>
          </Tooltip>

          <Tooltip title="Valor já recebido dos pedidos" arrow>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <MonetizationOn sx={{ color: theme.palette.success.main, fontSize: 20 }} />
              <Typography variant="body2" color={theme.palette.text.primary}>
                <strong>Recebido:</strong> R$ {totalOrderReceived.toFixed(2)}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3, alignItems: 'center' }}>
        <Typography variant="h6">Transações</Typography>
        <IconButton onClick={() => setFiltersVisible(!filtersVisible)}>
          <FilterListIcon />
        </IconButton>
      </Box>

      <Collapse in={filtersVisible}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3, flexWrap: 'wrap', gap: 2 }}>
          <TextField
            label="Buscar por Descrição"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ flex: 1, minWidth: '200px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="action" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Filtrar por Tipo"
            select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            sx={{ flex: 1, minWidth: '200px' }}
          >
            <MenuItem value="">Todos</MenuItem>
            {transactionTypes.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Filtrar por Método de Pagamento"
            select
            value={filterPaymentMethod}
            onChange={(e) => setFilterPaymentMethod(e.target.value)}
            sx={{ flex: 1, minWidth: '200px' }}
          >
            <MenuItem value="">Todos</MenuItem>
            {paymentMethods.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Collapse>

      {filteredCosts.length === 0 ? (
        <Paper elevation={1} sx={{ p: 3, mt: 4, textAlign: 'center' }}>
          <Typography variant="h6" color="textSecondary">
            Nenhuma transação encontrada
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Ajuste os filtros ou adicione uma nova transação para vê-la aqui.
          </Typography>
        </Paper>
      ) : (
        <>
          <TableContainer component={Paper} elevation={2}>
            <Table>
              <TableHead>
                <TableRow>
                  {['Descrição', 'Valor', 'Data', 'Tipo', 'Método de Pagamento', 'Ações'].map((header) => (
                    <TableCell key={header} sx={{ fontWeight: 'bold' }}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentCosts.map((cost) => (
                  <TableRow key={cost.id}>
                    {editingCost?.id === cost.id ? (
                      <>
                        <TableCell>
                          <TextField
                            label="Descrição"
                            value={editingCost.description}
                            onChange={(e) => setEditingCost({ ...editingCost, description: e.target.value })}
                            sx={{ width: '100%' }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Valor"
                            value={editingAmount}
                            onChange={(e) => {
                              handleAmountChange(e);
                            }}
                            onBlur={(e) => {
                              handleAmountBlur();
                              setEditingCost(prev => ({
                                ...prev,
                                amount: getAmountNumber()
                              }));
                            }}
                            inputProps={{
                              inputMode: 'numeric'
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {currencyNames['BRL'].symbol}
                                </InputAdornment>
                              ),
                            }}
                            sx={{ width: '100%' }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Data"
                            type="date"
                            value={editingCost.date}
                            onChange={(e) => setEditingCost({ ...editingCost, date: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: '100%' }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Tipo"
                            select
                            value={editingCost.type}
                            onChange={(e) => setEditingCost({ ...editingCost, type: e.target.value })}
                            sx={{ width: '100%' }}
                          >
                            {transactionTypes.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Método de Pagamento"
                            select
                            value={editingCost.paymentMethod}
                            onChange={(e) => setEditingCost({ ...editingCost, paymentMethod: e.target.value })}
                            sx={{ width: '100%' }}
                          >
                            {paymentMethods.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Salvar" arrow>
                            <IconButton color="primary" onClick={handleUpdateCost}>
                              <Save />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Cancelar" arrow>
                            <IconButton color="secondary" onClick={handleCancelEdit}>
                              <Cancel />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          <Tooltip title={cost.description} arrow>
                            <span>
                              {cost.description.length > DESCRIPTION_MAX_LENGTH
                                ? `${cost.description.slice(0, DESCRIPTION_MAX_LENGTH)}...`
                                : cost.description}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {currencyNames['BRL'].symbol} {normalizeValue(cost.amount)}
                        </TableCell>
                        <TableCell>{formatDate(cost.date)}</TableCell>
                        <TableCell>{cost.type}</TableCell>
                        <TableCell>{cost.paymentMethod}</TableCell>
                        <TableCell align="right">
                          <Tooltip title="Editar" arrow>
                            <IconButton onClick={() => handleEditClick(cost)} sx={{ marginRight: 1 }}>
                              <Edit />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Excluir" arrow>
                            <IconButton onClick={() => handleOpenDeleteDialog(cost)}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Pagination
              count={Math.ceil(filteredCosts.length / ITEMS_PER_PAGE)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              shape="rounded"
              sx={{ borderRadius: '50px' }}
            />
          </Box>
        </>
      )}

      <Paper elevation={2} sx={{ p: 2, mb: 3, mt: 4, borderRadius: '8px', backgroundColor: theme.palette.background.default }}>
        <Typography variant="subtitle1" gutterBottom>
          Totais das Transações Filtradas
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
            <Typography variant="body2" color={theme.palette.text.primary}>
              <strong>Total de Custos:</strong>
            </Typography>
            <Typography variant="h6" color={theme.palette.error.main}>
              R$ {totalExpenses.toFixed(2)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
            <Typography variant="body2" color={theme.palette.text.primary}>
              <strong>Total de Recebimentos:</strong>
            </Typography>
            <Typography variant="h6" color={theme.palette.success.main}>
              R$ {totalIncomes.toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Paper>

      {/* Modal de confirmação de exclusão */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <Typography>Tem certeza de que deseja excluir esta transação?</Typography>
          {selectedCost && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">
                <strong>Descrição:</strong> {selectedCost.description}
              </Typography>
              <Typography variant="body2">
                <strong>Valor:</strong> R$ {selectedCost.amount}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} variant="outlined" sx={{ borderRadius: '50px' }}>
            Voltar
          </Button>
          <Button onClick={confirmDeleteCost} color="error" variant="contained" sx={{ color: 'white', borderRadius: '50px' }}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const normalizeValue = (value) => {
  if (!value) return '0,00';

  // Converte para string primeiro
  const stringValue = String(value);

  // Se já estiver formatado, retorna como está
  if (stringValue.includes(',')) return stringValue;

  // Formata o número com duas casas decimais
  return parseFloat(stringValue).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export default CostList;
