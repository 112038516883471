import { db, auth } from '../firebaseConfig';
import { collection, doc, addDoc, getDocs, getDoc, updateDoc, query, where, orderBy, limit, startAfter } from 'firebase/firestore';

const getAgencyRef = async () => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  return doc(db, 'agencies', userDoc.data().agencyId);
};

// Função para adicionar um novo layout ao Firestore
export const addLayout = async (layout) => {
  try {
    const agencyRef = await getAgencyRef();
    const layoutsRef = collection(agencyRef, 'layouts');

    const timestamp = new Date().toISOString();
    const layoutWithMetadata = {
      ...layout,
      estaAtivo: true,
      metadata: {
        createdAt: timestamp,
        updatedAt: timestamp,
        deletedAt: null
      }
    };

    const docRef = await addDoc(layoutsRef, layoutWithMetadata);
    return docRef.id;
  } catch (error) {
    console.error('Erro ao adicionar layout:', error);
    throw error;
  }
};

export const updateLayout = async (layoutId, layoutData) => {
  try {
    const agencyRef = await getAgencyRef();
    const layoutRef = doc(agencyRef, 'layouts', layoutId);

    // Remover campos undefined do layoutData
    const cleanedLayoutData = removeUndefinedFields(layoutData);

    const timestamp = new Date().toISOString();
    const layoutWithMetadata = {
      ...cleanedLayoutData,
      metadata: {
        ...cleanedLayoutData.metadata,
        updatedAt: timestamp
      }
    };

    return await updateDoc(layoutRef, layoutWithMetadata);
  } catch (error) {
    console.error('Erro ao atualizar layout:', error);
    throw error;
  }
};

export const deleteLayout = async (layoutId) => {
  try {
    const agencyRef = await getAgencyRef();
    const layoutRef = doc(agencyRef, 'layouts', layoutId);

    const timestamp = new Date().toISOString();
    const deletionUpdate = {
      estaAtivo: false,
      metadata: {
        deletedAt: timestamp,
        updatedAt: timestamp
      }
    };

    return await updateDoc(layoutRef, deletionUpdate);
  } catch (error) {
    console.error('Erro ao excluir layout:', error);
    throw error;
  }
};

// Função para atualizar um layout específico no Firestore
const removeUndefinedFields = (obj) => {
  const cleanObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) {
      cleanObj[key] = obj[key];
    }
  });
  return cleanObj;
};

export const getAllLayouts = async () => {
  try {
    const agencyRef = await getAgencyRef();
    const layoutsRef = collection(agencyRef, 'layouts');
    const q = query(layoutsRef, where('estaAtivo', '==', true));
    const snapshot = await getDocs(q);

    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Erro ao buscar todos os layouts:', error);
    throw error;
  }
};

export const getPaginatedLayouts = async (pageSize = 12, startAfterDoc = null, sortOrder = 'asc', searchTerm = '', currentPage = 1) => {
  try {
    const agencyRef = await getAgencyRef();
    const layoutsRef = collection(agencyRef, 'layouts');

    let queryConstraints = [
      where('estaAtivo', '==', true),
      orderBy('name', sortOrder)
    ];

    // Primeira query para pegar todos os layouts ativos
    const fullQuery = query(layoutsRef, ...queryConstraints);
    const fullSnapshot = await getDocs(fullQuery);
    let allLayouts = fullSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // Se houver termo de busca, filtra os layouts
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      allLayouts = allLayouts.filter(layout =>
        layout.id.toLowerCase().includes(searchTermLower) ||
        (layout.name && layout.name.toLowerCase().includes(searchTermLower))
      );

      // Busca por ID exato
      try {
        const layoutDocRef = doc(agencyRef, 'layouts', searchTerm);
        const docSnapshot = await getDoc(layoutDocRef);
        if (docSnapshot.exists() && docSnapshot.data().estaAtivo) {
          const layoutById = { id: docSnapshot.id, ...docSnapshot.data() };
          if (!allLayouts.some(l => l.id === layoutById.id)) {
            allLayouts.unshift(layoutById);
          }
        }
      } catch (error) {
      }
    }

    // Calcula o total antes de aplicar a paginação
    const total = allLayouts.length;

    // Aplica a paginação nos resultados
    const startIndex = (currentPage - 1) * pageSize;
    const paginatedLayouts = allLayouts.slice(startIndex, startIndex + pageSize);

    return {
      layouts: paginatedLayouts,
      lastDoc: fullSnapshot.docs[paginatedLayouts.length - 1] || null,
      total,
      hasMore: total > startIndex + pageSize
    };
  } catch (error) {
    console.error('Erro ao buscar layouts:', error);
    throw new Error('Falha ao buscar layouts');
  }
};

// Função para obter um layout específico pelo ID
export const getLayoutById = async (layoutId) => {
  try {
    const agencyRef = await getAgencyRef();
    const layoutRef = doc(agencyRef, 'layouts', layoutId);
    const layoutDoc = await getDoc(layoutRef);

    if (!layoutDoc.exists()) {
      throw new Error('Layout não encontrado');
    }

    return { id: layoutDoc.id, ...layoutDoc.data() };
  } catch (error) {
    console.error('Erro ao buscar layout:', error);
    throw error;
  }
};
