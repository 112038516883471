import { db, auth } from '../firebaseConfig';
import { collection, doc, addDoc, getDocs, updateDoc, getDoc, writeBatch, arrayUnion, arrayRemove, query, where } from 'firebase/firestore';
import { getPassengerById } from './PassengerService';

// Obtém a referência do documento do usuário autenticado
const getAgencyRef = async () => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  return doc(db, 'agencies', userDoc.data().agencyId);
};

// Função para manipular o status de pedidos e reservas
const updateOrderAndReservationStatus = async (orderId, travelId) => {
  const agencyRef = await getAgencyRef();
  const orderRef = doc(agencyRef, 'orders', orderId);

  // Buscar todas as reservas do pedido
  const reservationsRef = collection(agencyRef, 'reservations');
  const reservationsQuery = query(
    reservationsRef,
    where('orderId', '==', orderId),
    where('travelId', '==', travelId)
  );
  const reservationsSnapshot = await getDocs(reservationsQuery);

  // Verificar status das reservas
  let allReservationsCancelled = true;
  const orderDoc = await getDoc(orderRef);
  const orderData = orderDoc.data();

  const valorTotal = Number(orderData.detalhesPagamento?.valorTotal || 0);
  const valorPago = Number(orderData.detalhesPagamento?.valorPago || 0);
  const valorRestante = valorTotal - valorPago;

  reservationsSnapshot.forEach((reservationDoc) => {
    const reservation = reservationDoc.data();
    if (reservation.status !== 'Cancelada') {
      allReservationsCancelled = false;
    }
  });

  // Determinar novo status do pedido
  let newOrderStatus = 'Indefinido';

  if (allReservationsCancelled) {
    newOrderStatus = 'Cancelada';
  } else if (valorRestante > 0) {
    newOrderStatus = 'Pagamento Pendente';
  } else if (valorPago === valorTotal && valorRestante === 0) {
    newOrderStatus = 'Pago';
  }

  // Atualizar status do pedido
  await updateDoc(orderRef, { status: newOrderStatus });

  // Atualizar status das reservas em lote
  const batch = writeBatch(db);
  reservationsSnapshot.forEach((reservationDoc) => {
    const reservation = reservationDoc.data();
    if (reservation.status !== 'Cancelada') {
      const reservationRef = doc(agencyRef, 'reservations', reservationDoc.id);
      batch.update(reservationRef, { status: newOrderStatus });
    }
  });

  await batch.commit();
};

const updatePassengerReservationRefs = async (passengerId, reservation, operation = 'add') => {
  const agencyRef = await getAgencyRef();
  const passengerRef = doc(agencyRef, 'passengers', passengerId);

  const reservationRef = {
    id: reservation.id,
    travelId: reservation.travelId,
    orderId: reservation.orderId,
    dataAdicionado: new Date().toISOString(),
  };

  try {
    const passengerDoc = await getDoc(passengerRef);
    if (!passengerDoc.exists()) {
      throw new Error('Passageiro não encontrado');
    }

    if (operation === 'add') {
      await updateDoc(passengerRef, {
        reservationRefs: arrayUnion(reservationRef)
      });
    } else if (operation === 'remove' || operation === 'update') {
      const currentData = passengerDoc.data();
      const currentRefs = currentData.reservationRefs || [];

      const updatedRefs = currentRefs.filter(ref =>
        ref.id !== reservation.id ||
        ref.travelId !== reservation.travelId ||
        ref.orderId !== reservation.orderId
      );

      if (operation === 'update') {
        updatedRefs.push(reservationRef);
      }

      await updateDoc(passengerRef, {
        reservationRefs: updatedRefs
      });
    }
  } catch (error) {
    console.error('Erro ao atualizar referências de reserva do passageiro:', error);
    throw error;
  }
};

// Função para adicionar um pedido
export const addOrder = async (order) => {
  const agencyRef = await getAgencyRef();
  const ordersRef = collection(agencyRef, 'orders');

  const timestamp = new Date().toISOString();
  const orderWithMetadata = {
    ...order,
    metadata: {
      createdAt: timestamp,
      updatedAt: timestamp,
      deletedAt: null
    }
  };

  const orderDoc = await addDoc(ordersRef, orderWithMetadata);
  return orderDoc.id;
};

export const updateOrder = async (orderId, orderData) => {
  const agencyRef = await getAgencyRef();
  const orderRef = doc(agencyRef, 'orders', orderId);

  const timestamp = new Date().toISOString();
  const orderWithMetadata = {
    ...orderData,
    metadata: {
      ...orderData.metadata,
      updatedAt: timestamp
    }
  };

  await updateDoc(orderRef, orderWithMetadata);
  // Passamos orderId e travelId para a função atualizada de status
  await updateOrderAndReservationStatus(orderId, orderData.travelId);
};

export const cancelOrder = async (travelId, orderId) => {
  const agencyRef = await getAgencyRef();
  const orderRef = doc(agencyRef, 'orders', orderId);

  // Buscar reservas associadas ao pedido
  const reservationsRef = collection(agencyRef, 'reservations');
  const reservationsQuery = query(
    reservationsRef,
    where('orderId', '==', orderId),
    where('travelId', '==', travelId)
  );
  const reservationsSnapshot = await getDocs(reservationsQuery);

  const batch = writeBatch(db);

  // Atualizar status das reservas
  reservationsSnapshot.forEach((reservationDoc) => {
    const reservationRef = doc(agencyRef, 'reservations', reservationDoc.id);
    batch.update(reservationRef, { status: 'Cancelada' });
  });

  // Atualizar status do pedido
  batch.update(orderRef, { status: 'Cancelada' });

  await batch.commit();
  await updateReservationCountForTravel(travelId);
};

export const addReservation = async (orderId, reservation) => {
  const agencyRef = await getAgencyRef();
  const reservationsRef = collection(agencyRef, 'reservations');

  try {
    const timestamp = new Date().toISOString();
    const reservationWithMetadata = {
      ...reservation,
      orderId,
      metadata: {
        createdAt: timestamp,
        updatedAt: timestamp,
        deletedAt: null
      }
    };

    // Adicionar a reserva
    const reservationDoc = await addDoc(reservationsRef, reservationWithMetadata);

    // Atualizar referências no documento do passageiro
    await updatePassengerReservationRefs(reservation.passengerId, {
      id: reservationDoc.id,
      travelId: reservation.travelId,
      orderId: orderId,
      status: reservation.status || 'Pendente'
    }, 'add');

    // Atualizar status do pedido e contagem de reservas
    await updateOrderAndReservationStatus(orderId, reservation.travelId);
    await updateReservationCountForTravel(reservation.travelId);

    return {
      reservationId: reservationDoc.id,
      metadata: {
        createdAt: timestamp
      }
    };
  } catch (error) {
    console.error('Erro ao adicionar reserva:', error);
    throw error;
  }
};

export const updateReservation = async (reservationId, reservation) => {
  const agencyRef = await getAgencyRef();
  const reservationRef = doc(agencyRef, 'reservations', reservationId);

  if (!reservationId || !reservation.travelId || !reservation.orderId) {
    throw new Error('Dados da reserva inválidos');
  }

  try {
    // Verificar se a reserva existe
    const oldReservationSnap = await getDoc(reservationRef);
    if (!oldReservationSnap.exists()) {
      throw new Error('Reserva não encontrada');
    }

    const timestamp = new Date().toISOString();
    const reservationWithMetadata = {
      ...reservation,
      metadata: {
        ...oldReservationSnap.data().metadata,
        updatedAt: timestamp
      }
    };

    // Atualizar a reserva
    await updateDoc(reservationRef, reservationWithMetadata);

    // Atualizar referências do passageiro
    await updatePassengerReservationRefs(reservation.passengerId, {
      id: reservationId,
      travelId: reservation.travelId,
      orderId: reservation.orderId,
      status: reservation.status
    }, 'update');

    await updateOrderAndReservationStatus(reservation.orderId, reservation.travelId);
    await updateReservationCountForTravel(reservation.travelId);
  } catch (error) {
    console.error('Erro ao atualizar reserva:', error);
    throw error;
  }
};

export const cancelReservation = async (travelId, orderId, reservationId) => {
  const agencyRef = await getAgencyRef();
  const reservationRef = doc(agencyRef, 'reservations', reservationId);

  try {
    // Verificar se a reserva existe
    const reservationSnap = await getDoc(reservationRef);
    if (!reservationSnap.exists()) {
      throw new Error('Reserva não encontrada');
    }

    const reservationData = reservationSnap.data();

    // Atualizar status da reserva
    await updateDoc(reservationRef, { status: 'Cancelada' });

    // Atualizar referências do passageiro
    await updatePassengerReservationRefs(reservationData.passengerId, {
      id: reservationId,
      travelId,
      orderId,
      status: 'Cancelada'
    }, 'update');

    await updateOrderAndReservationStatus(orderId, travelId);
    await updateReservationCountForTravel(travelId);
  } catch (error) {
    console.error('Erro ao cancelar reserva:', error);
    throw error;
  }
};

// Função para obter todas as reservas por ID de viagem
export const getReservationsByTravelId = async (travelId) => {
  try {
    const agencyRef = await getAgencyRef();

    // Buscar todas as reservas da viagem
    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(
      reservationsRef,
      where('travelId', '==', travelId)
    );
    const reservationsSnapshot = await getDocs(reservationsQuery);

    const allReservations = [];

    for (const reservationDoc of reservationsSnapshot.docs) {
      const reservationData = reservationDoc.data();

      // Buscar dados do pedido associado
      const orderRef = doc(agencyRef, 'orders', reservationData.orderId);
      const orderSnap = await getDoc(orderRef);
      const orderData = orderSnap.data();

      // Combinar dados da reserva com dados do pedido
      allReservations.push({
        id: reservationDoc.id,
        ...reservationData,
        orderId: reservationData.orderId,
        detalhesPagamento: orderData?.detalhesPagamento,
        status: reservationData.status || orderData?.status
      });
    }

    // Atualizar status dos pedidos
    const uniqueOrderIds = [...new Set(allReservations.map(r => r.orderId))];
    await Promise.all(uniqueOrderIds.map(orderId =>
      updateOrderAndReservationStatus(orderId, travelId)
    ));

    return allReservations;
  } catch (error) {
    console.error('Erro ao buscar reservas por ID de viagem:', error);
    throw error;
  }
};

export const getSortedReservationsByTravelId = async (travelId) => {
  try {
    const agencyRef = await getAgencyRef();

    // Buscar todas as reservas da viagem
    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(
      reservationsRef,
      where('travelId', '==', travelId)
    );
    const reservationsSnapshot = await getDocs(reservationsQuery);

    const allReservations = [];

    for (const reservationDoc of reservationsSnapshot.docs) {
      const reservationData = reservationDoc.data();

      // Buscar dados do pedido associado
      const orderRef = doc(agencyRef, 'orders', reservationData.orderId);
      const orderSnap = await getDoc(orderRef);
      const orderData = orderSnap.data();

      // Combinar dados da reserva com dados do pedido
      allReservations.push({
        id: reservationDoc.id,
        ...reservationData,
        orderId: reservationData.orderId,
        detalhesPagamento: orderData?.detalhesPagamento,
        status: reservationData.status || orderData?.status
      });
    }

    // Atualizar status dos pedidos
    const uniqueOrderIds = [...new Set(allReservations.map(r => r.orderId))];
    await Promise.all(uniqueOrderIds.map(orderId =>
      updateOrderAndReservationStatus(orderId, travelId)
    ));

    // Ordenar reservas
    allReservations.sort((a, b) => {
      if (a.status === 'Cancelada' && b.status !== 'Cancelada') return 1;
      if (a.status !== 'Cancelada' && b.status === 'Cancelada') return -1;
      return (a.numeroAssento || 0) - (b.numeroAssento || 0);
    });

    return allReservations;
  } catch (error) {
    console.error('Erro ao buscar reservas por ID de viagem:', error);
    throw error;
  }
};

export const getSortedOrdersByTravelId = async (travelId) => {
  try {
    const agencyRef = await getAgencyRef();

    // Buscar todos os pedidos da viagem
    const ordersRef = collection(agencyRef, 'orders');
    const ordersQuery = query(ordersRef, where('travelId', '==', travelId));
    const ordersSnapshot = await getDocs(ordersQuery);

    const sortedOrders = [];

    for (const orderDoc of ordersSnapshot.docs) {
      const orderData = { id: orderDoc.id, ...orderDoc.data() };

      // Buscar reservas associadas ao pedido
      const reservationsRef = collection(agencyRef, 'reservations');
      const reservationsQuery = query(
        reservationsRef,
        where('orderId', '==', orderDoc.id),
        where('travelId', '==', travelId)
      );
      const reservationsSnapshot = await getDocs(reservationsQuery);

      // Adicionar reservas ao pedido
      orderData.reservations = reservationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Atualizar status do pedido e reservas
      await updateOrderAndReservationStatus(orderDoc.id, travelId);

      sortedOrders.push(orderData);
    }

    // Ordenação: cancelados por último, depois por ID
    sortedOrders.sort((a, b) => {
      if (a.status === 'Cancelada' && b.status !== 'Cancelada') return 1;
      if (a.status !== 'Cancelada' && b.status === 'Cancelada') return -1;
      return a.id.localeCompare(b.id);
    });

    return sortedOrders;
  } catch (error) {
    console.error('Erro ao buscar e ordenar pedidos por ID de viagem:', error);
    throw error;
  }
};

export const calculateTotalsByTravelId = async (travelId) => {
  const agencyRef = await getAgencyRef();

  // Buscar todos os pedidos da viagem
  const ordersRef = collection(agencyRef, 'orders');
  const ordersQuery = query(ordersRef, where('travelId', '==', travelId));
  const ordersSnapshot = await getDocs(ordersQuery);

  let totalReceivable = 0;
  let totalReceived = 0;

  ordersSnapshot.forEach((orderDoc) => {
    const orderData = orderDoc.data();
    if (orderData.status !== 'Cancelada') {
      const valorPago = parseFloat(orderData.detalhesPagamento?.valorPago || 0);
      const valorRestante = parseFloat(orderData.detalhesPagamento?.valorRestante || 0);

      totalReceived += valorPago;
      totalReceivable += valorRestante;
    }
  });

  return { totalReceivable, totalReceived };
};

// Função para obter todos os assentos disponíveis
export const getAvailableSeats = async (travelId, quantidadeAssentos = 40) => {
  const agencyRef = await getAgencyRef();

  // Buscar todas as reservas da viagem
  const reservationsRef = collection(agencyRef, 'reservations');
  const reservationsQuery = query(reservationsRef, where('travelId', '==', travelId));
  const reservationsSnapshot = await getDocs(reservationsQuery);

  // Coletar assentos reservados ativos
  const reservedSeats = reservationsSnapshot.docs
    .map(doc => doc.data())
    .filter(reservation => reservation.status !== 'Cancelada')
    .map(reservation => reservation.numeroAssento);

  // Gerar lista de todos os assentos
  const allSeats = Array.from(
    { length: quantidadeAssentos },
    (_, i) => ({ id: i + 1, number: i + 1 })
  );

  return allSeats;
};

export const getReservedSeats = async (travelId) => {
  const agencyRef = await getAgencyRef();

  // Buscar todas as reservas da viagem
  const reservationsRef = collection(agencyRef, 'reservations');
  const reservationsQuery = query(reservationsRef, where('travelId', '==', travelId));
  const reservationsSnapshot = await getDocs(reservationsQuery);

  const reservedSeats = [];

  for (const reservationDoc of reservationsSnapshot.docs) {
    const reservationData = reservationDoc.data();

    // Buscar informações do passageiro
    const passengerRef = doc(agencyRef, 'passengers', reservationData.passengerId);
    const passengerSnap = await getDoc(passengerRef);
    const passengerInfo = passengerSnap.exists()
      ? { id: passengerSnap.id, ...passengerSnap.data() }
      : null;

    reservedSeats.push({
      number: reservationData.numeroAssento,
      status: reservationData.status,
      passenger: passengerInfo,
      orderId: reservationData.orderId
    });
  }

  return reservedSeats;
};

// Função para obter um pedido pelo ID
export const getOrderById = async (travelId, orderId) => {
  if (!travelId || !orderId) {
    throw new Error('travelId ou orderId não fornecido');
  }

  const agencyRef = await getAgencyRef();
  const orderRef = doc(agencyRef, 'orders', orderId);

  const orderSnapshot = await getDoc(orderRef);
  if (!orderSnapshot.exists()) {
    throw new Error('Pedido não encontrado');
  }

  const order = { id: orderSnapshot.id, ...orderSnapshot.data() };

  // Buscar reservas associadas ao pedido
  const reservationsRef = collection(agencyRef, 'reservations');
  const reservationsQuery = query(
    reservationsRef,
    where('orderId', '==', orderId),
    where('travelId', '==', travelId)
  );
  const reservationsSnapshot = await getDocs(reservationsQuery);

  order.reservations = reservationsSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));

  await updateOrderAndReservationStatus(orderId, travelId);

  return order;
};

export const getOrdersByTravelId = async (travelId) => {
  const agencyRef = await getAgencyRef();

  // Buscar todos os pedidos da viagem
  const ordersRef = collection(agencyRef, 'orders');
  const ordersQuery = query(ordersRef, where('travelId', '==', travelId));
  const ordersSnapshot = await getDocs(ordersQuery);

  const orders = [];

  for (const orderDoc of ordersSnapshot.docs) {
    const orderData = { id: orderDoc.id, ...orderDoc.data() };

    // Buscar reservas associadas ao pedido
    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(
      reservationsRef,
      where('orderId', '==', orderDoc.id),
      where('travelId', '==', travelId)
    );
    const reservationsSnapshot = await getDocs(reservationsQuery);

    orderData.reservations = reservationsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    await updateOrderAndReservationStatus(orderDoc.id, travelId);

    orders.push(orderData);
  }

  return orders;
};

export const getReservationById = async (travelId, orderId, reservationId) => {
  if (!travelId || !orderId || !reservationId) {
    throw new Error('travelId, orderId, ou reservationId não fornecido');
  }

  const agencyRef = await getAgencyRef();
  const reservationRef = doc(agencyRef, 'reservations', reservationId);

  const reservationSnapshot = await getDoc(reservationRef);
  if (!reservationSnapshot.exists()) {
    throw new Error('Reserva não encontrada');
  }

  const reservation = { id: reservationSnapshot.id, ...reservationSnapshot.data() };

  // Buscar dados do pedido
  const orderRef = doc(agencyRef, 'orders', orderId);
  const orderSnapshot = await getDoc(orderRef);

  if (orderSnapshot.exists()) {
    const orderData = orderSnapshot.data();
    reservation.detalhesPagamento = orderData.detalhesPagamento;
    reservation.orderStatus = orderData.status;
  }

  // Buscar dados do passageiro
  const passengerRef = doc(agencyRef, 'passengers', reservation.passengerId);
  const passengerSnapshot = await getDoc(passengerRef);

  if (passengerSnapshot.exists()) {
    reservation.passengerInfo = {
      id: passengerSnapshot.id,
      ...passengerSnapshot.data()
    };
  }

  return reservation;
};

export const updateReservationCountForTravel = async (travelId) => {
  try {
    const agencyRef = await getAgencyRef();
    const travelRef = doc(agencyRef, 'travels', travelId);

    // Verificar se a viagem existe
    const travelDoc = await getDoc(travelRef);
    if (!travelDoc.exists()) {
      console.warn(`Travel with ID ${travelId} not found`);
      return;
    }

    // Buscar todas as reservas da viagem
    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(
      reservationsRef,
      where('travelId', '==', travelId)
    );
    const reservationsSnapshot = await getDocs(reservationsQuery);

    // Contar reservas válidas
    const totalReservations = reservationsSnapshot.docs.filter(doc => {
      const data = doc.data();
      return data.status !== 'Cancelada' && data.numeroAssento !== null;
    }).length;

    // Atualizar contagem na viagem
    await updateDoc(travelRef, {
      numReservas: totalReservations,
    });
  } catch (error) {
    console.error('Error updating reservation count:', error);
  }
};