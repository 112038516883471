import React, { useState } from 'react';
import { Card, CardContent, Box, Typography, Tooltip, IconButton, Divider, Avatar, Grid, Collapse } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useTheme } from '@mui/material/styles';
import { formatDate, formatCPF } from '../../utils/utils';

const OrderCard = ({ order, travel, onEditOrder, onCancelOrder, onCardClick, hideTravelInfo }) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const detalhesPagamento = order.detalhesPagamento || {};
  const valorTotal = Number(detalhesPagamento.valorTotal || 0);
  const valorPago = Number(detalhesPagamento.valorPago || 0);
  const valorRestante = valorTotal - valorPago;

  const seatLabel = order.reservations?.length === 1 ? 'Assento' : 'Assentos';
  const sortedSeats = order.reservations
    ? order.reservations
      .map(reservation => reservation.numeroAssento)
      .filter(numeroAssento => numeroAssento !== null)
      .sort((a, b) => a - b)
      .join(', ') || 'Nenhum assento'
    : 'Nenhum assento';

  const orderStatus = order.status;
  const statusColor = orderStatus === 'Pago'
    ? theme.palette.success.main
    : orderStatus === 'Cancelada'
      ? theme.palette.error.main
      : theme.palette.warning.main;

  return (
    <Card
      onClick={() => onCardClick(order)}
      sx={{
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        position: 'relative',
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': { transform: 'scale(1.02)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)' },
        mb: 1,
        borderRadius: 2,
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden'
      }}
    >
      <CardContent sx={{ 
        padding: { xs: '8px !important', sm: '16px !important' },
        '&:last-child': { paddingBottom: '8px !important' }
      }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between', 
          alignItems: { xs: 'flex-start', sm: 'center' },
          gap: { xs: 1, sm: 0 }
        }}>
          <Box sx={{ 
            width: { xs: '100%', sm: '75%' },
            minWidth: 0 // Permite que o conteúdo seja reduzido
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'flex-start',
              mb: 1,
              gap: 1
            }}>
              <Avatar
                sx={{
                  bgcolor: theme.palette.primary.main,
                  width: { xs: 30, sm: 40 },
                  height: { xs: 30, sm: 40 },
                  flexShrink: 0
                }}
              >
                <AttachMoneyIcon sx={{ fontSize: { xs: 18, sm: 24 } }} />
              </Avatar>
              <Box sx={{ minWidth: 0 }}> {/* Permite que o texto quebre */}
                <Typography
                  variant="body1"
                  sx={{ 
                    fontWeight: 'bold',
                    fontSize: { xs: '0.9rem', sm: '1rem' },
                    color: theme.palette.text.primary,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Pedido: {order.id}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  Valor Total: R$ {valorTotal.toFixed(2)}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  Valor Pago: R$ {valorPago.toFixed(2)}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  Valor Restante: R$ {valorRestante.toFixed(2)}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {seatLabel}: {sortedSeats}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'flex-end', sm: 'flex-end' },
            width: { xs: '100%', sm: 'auto' },
            mt: { xs: 1, sm: 0 }
          }}>
            <Tooltip title={`Status: ${orderStatus}`}>
              {orderStatus === 'Pago' ? (
                <CheckCircleIcon sx={{ color: statusColor, fontSize: { xs: 20, sm: 24 }, mr: { xs: 0.5, sm: 1 } }} />
              ) : orderStatus === 'Cancelada' ? (
                <CancelIcon sx={{ color: statusColor, fontSize: { xs: 20, sm: 24 }, mr: { xs: 0.5, sm: 1 } }} />
              ) : (
                <ErrorIcon sx={{ color: statusColor, fontSize: { xs: 20, sm: 24 }, mr: { xs: 0.5, sm: 1 } }} />
              )}
            </Tooltip>

            <Tooltip title="Expandir">
              <IconButton 
                onClick={handleExpandClick}
                sx={{ padding: { xs: '4px', sm: '8px' } }}
              >
                {expanded ? <ExpandLessIcon sx={{ fontSize: { xs: 20, sm: 24 } }} /> : <ExpandMoreIcon sx={{ fontSize: { xs: 20, sm: 24 } }} />}
              </IconButton>
            </Tooltip>

            <Tooltip title="Editar">
              <IconButton 
                edge="end" 
                aria-label="edit" 
                onClick={(e) => { e.stopPropagation(); onEditOrder(order); }}
                sx={{ padding: { xs: '4px', sm: '8px' } }}
              >
                <EditIcon sx={{ fontSize: { xs: 20, sm: 24 } }} />
              </IconButton>
            </Tooltip>

            {orderStatus !== 'Cancelada' && (
              <Tooltip title="Cancelar">
                <IconButton 
                  edge="end" 
                  aria-label="delete" 
                  onClick={(e) => { e.stopPropagation(); onCancelOrder(order.id, order.travelId); }}
                  sx={{ padding: { xs: '4px', sm: '8px' } }}
                >
                  <CancelIcon sx={{ fontSize: { xs: 20, sm: 24 } }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                sx={{ 
                  fontWeight: 'bold',
                  fontSize: { xs: '0.9rem', sm: '1rem' },
                  color: theme.palette.text.primary,
                  mb: 0.5
                }}
              >
                Informações do Pagador:
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: { xs: '0.8rem', sm: '0.9rem' },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                Nome: {detalhesPagamento.nomePagador || 'Não informado'}
              </Typography>

              {detalhesPagamento.passaportePagador ? (
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  Passaporte: {detalhesPagamento.passaportePagador || 'Não informado'}
                </Typography>
              ) : (
                <>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      fontSize: { xs: '0.8rem', sm: '0.9rem' },
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    CPF: {detalhesPagamento.cpfPagador ? formatCPF(detalhesPagamento.cpfPagador) : 'Não informado'}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      fontSize: { xs: '0.8rem', sm: '0.9rem' },
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    RG: {detalhesPagamento.rgPagador || 'Não informado'}
                  </Typography>
                </>
              )}

              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: { xs: '0.8rem', sm: '0.9rem' },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                Método de Pagamento: {detalhesPagamento.metodoPagamento || 'Não informado'}
              </Typography>
            </Grid>

            {!hideTravelInfo && travel && (
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body2"
                  sx={{ 
                    fontWeight: 'bold',
                    fontSize: { xs: '0.9rem', sm: '1rem' },
                    color: theme.palette.text.primary,
                    mb: 0.5
                  }}
                >
                  Informações da Viagem:
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  Identificador: {travel?.identificador || 'Não informado'}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  Origem: {travel?.origem || 'Não informado'}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  Destino: {travel?.destino || 'Não informado'}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  Data de Ida: {travel?.dataIda ? formatDate(travel.dataIda) : 'Não informada'}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default OrderCard;