// components/BusinessRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './useAuthState';
import TravelLoading from '../components/common/TravelLoading';
import { isBusinessAgency } from '../services/AgencyService';

export const BusinessRoute = ({ children }) => {
  const { currentUser, loading, initialized } = useAuth();
  const location = useLocation();
  const [isChecking, setIsChecking] = useState(true);
  const [isBusiness, setIsBusiness] = useState(false);

  useEffect(() => {
    const checkBusinessStatus = async () => {
      if (currentUser?.uid) {
        try {
          const businessStatus = await isBusinessAgency(currentUser.uid);
          setIsBusiness(businessStatus);
        } catch (error) {
          console.error('Error checking business status:', error);
          setIsBusiness(false);
        }
      }
      setIsChecking(false);
    };

    checkBusinessStatus();
  }, [currentUser]);

  if (!initialized || loading || isChecking) {
    return <TravelLoading />;
  }

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!currentUser.userData?.isApproved) {
    return <Navigate to="/pendente-aprovacao" replace />;
  }

  if (!isBusiness) {
    return <Navigate to="/" replace />;
  }

  return children;
};