import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatCPF, formatTelefone, formatDate } from './utils';
import logo from '../assets/logo-travell-combr.png';
import { getPassengerById } from '../services/PassengerService';

const exportReservationToPDF = async (reservation, passenger, travel) => {
  const doc = new jsPDF();
  doc.setFont('helvetica', 'normal');

  const addWrappedText = (text, x, y, maxWidth, lineHeight) => {
    const words = text.split(' ');
    let line = '';
    let lines = [];

    words.forEach((word) => {
      let testLine = line + word + ' ';
      let testWidth = doc.getTextWidth(testLine);

      if (testWidth > maxWidth) {
        lines.push(line.trim());
        line = word + ' ';
      } else {
        line = testLine;
      }
    });

    lines.push(line.trim());

    lines.forEach((line, index) => {
      doc.text(line, x, y + index * lineHeight);
    });

    return y + lines.length * lineHeight;
  };

  const img = new Image();
  img.src = logo;

  img.onload = async () => {
    const pageWidth = doc.internal.pageSize.width;

    // Adiciona a logo e torna-a clicável
    const logoX = pageWidth - 65;
    const logoY = 10;
    const logoWidth = 60;
    const logoHeight = 15;

    doc.addImage(img, 'PNG', logoX, logoY, logoWidth, logoHeight);
    doc.link(logoX, logoY, logoWidth, logoHeight, { url: 'https://travell.com.br' }); // Torna a logo clicável

    // Adiciona o título
    doc.setFontSize(18);
    doc.setTextColor(30, 30, 30);
    doc.text('Detalhes da Reserva', 60, 20);  // Reposicionando título mais próximo da logo
    doc.setLineWidth(0.2);

    const drawSectionBorder = (startX, startY, width, height, bgColor = [240, 240, 240]) => {
      doc.setFillColor(...bgColor);
      doc.rect(startX, startY, width, height, 'F');
      doc.setDrawColor(200, 200, 200);
      doc.setLineWidth(0.5);
      doc.roundedRect(startX, startY, width, height, 2, 2, 'S');
    };

    let currentY = 36;

    // Sessão de Assento
    drawSectionBorder(12, currentY, 180, 18);
    doc.setFontSize(14);
    doc.text('Assento', 14, currentY + 8);
    doc.setFontSize(12);
    currentY = addWrappedText(`Número do Assento: ${reservation.numeroAssento || 'Ocupante de Colo'}`, 14, currentY + 12, 180, 6);

    // Sessão de Informações da Viagem
    currentY += 10;
    const viagemHeight = travel.somenteIda ? 36 : 44;
    drawSectionBorder(12, currentY, 180, viagemHeight, [250, 250, 250]);
    doc.setFontSize(14);
    doc.text('Informações da Viagem', 14, currentY + 8);
    doc.setFontSize(12);
    currentY = addWrappedText(`ID: ${travel.identificador}`, 14, currentY + 14, 88, 6);
    currentY = addWrappedText(`Origem: ${travel.origem}`, 14, currentY, 88, 6);
    currentY = addWrappedText(`Destino: ${travel.destino}`, 14, currentY, 88, 6);
    currentY = addWrappedText(`Data de Ida: ${formatDate(travel.dataIda)} ${travel.horarioIda}`, 14, currentY, 88, 6);

    if (!travel.somenteIda) {
      currentY = addWrappedText(`Data de Retorno: ${formatDate(travel.dataRetorno)} ${travel.horarioRetorno}`, 14, currentY, 88, 6);
    } else {
      doc.setTextColor('blue');
      currentY = addWrappedText(`Somente Ida`, 14, currentY, 88, 6);
      doc.setTextColor('black');
    }

    currentY += 14;

    // Informações do Passageiro e Responsável em Colunas
    drawSectionBorder(12, currentY, 88, 80);
    doc.setFontSize(14);
    doc.text('Informações do Passageiro', 14, currentY + 8);
    doc.setFontSize(12);
    let startY = currentY + 14;
    startY = addWrappedText(`Nome: ${passenger.nome}`, 14, startY, 88, 6);
    startY = addWrappedText(`CPF: ${passenger.cpf ? formatCPF(passenger.cpf) : 'Não informado'}`, 14, startY, 88, 6);
    startY = addWrappedText(`${passenger.estrangeiro ? 'Passaporte' : 'RG'}: ${passenger.estrangeiro ? passenger.passaporte || 'Não informado' : passenger.rg || 'Não informado'}`, 14, startY, 88, 6);

    if (passenger.telefone) startY = addWrappedText(`Telefone: ${formatTelefone(passenger.telefone)}`, 14, startY, 88, 6);
    if (passenger.endereco) startY = addWrappedText(`Endereço: ${passenger.endereco}`, 14, startY, 88, 6);
    if (passenger.menorDeIdade) {
      doc.setTextColor('red');
      startY = addWrappedText('Menor de Idade', 14, startY, 88, 6);
      doc.setTextColor('black');
    }
    if (passenger.estrangeiro) {
      doc.setTextColor('blue');
      startY = addWrappedText('Estrangeiro', 14, startY, 88, 6);
      doc.setTextColor('black');
    }

    if (passenger.responsavelId) {
      const responsavel = await getPassengerById(passenger.responsavelId);
      if (responsavel) {
        drawSectionBorder(105, currentY, 88, 80, [245, 245, 245]);
        doc.setFontSize(14);
        doc.text('Responsável', 107, currentY + 8);
        doc.setFontSize(12);
        let respY = currentY + 14;
        respY = addWrappedText(`Nome: ${responsavel.nome}`, 107, respY, 88, 6);
        respY = addWrappedText(`CPF: ${responsavel.cpf ? formatCPF(responsavel.cpf) : 'Não informado'}`, 107, respY, 88, 6);
        respY = addWrappedText(`${responsavel.estrangeiro ? 'Passaporte' : 'RG'}: ${responsavel.estrangeiro ? responsavel.passaporte || 'Não informado' : responsavel.rg || 'Não informado'}`, 107, respY, 88, 6);
        respY = addWrappedText(`Telefone: ${formatTelefone(responsavel.telefone)}`, 107, respY, 88, 6);
        if (responsavel.estrangeiro) {
          doc.setTextColor('blue');
          respY = addWrappedText('Estrangeiro', 107, respY, 88, 6);
          doc.setTextColor('black');
        }
      }
    }

    currentY += 92;

    // Sessão de Pagamento e Status
    drawSectionBorder(12, currentY, 180, 60);
    doc.setFontSize(14);
    doc.text('Informações de Pagamento e Status', 14, currentY + 8);
    doc.setFontSize(12);
    let pagadorY = currentY + 14;
    pagadorY = addWrappedText(`Nome do Pagador: ${reservation.detalhesPagamento?.nomePagador || 'Não informado'}`, 14, pagadorY, 180, 6);
    pagadorY = addWrappedText(`Método de Pagamento: ${reservation.detalhesPagamento?.metodoPagamento || 'Não informado'}`, 14, pagadorY, 180, 6);
    pagadorY = addWrappedText(`Valor Total: R$ ${Number(reservation.detalhesPagamento?.valorTotal || 0).toFixed(2)}`, 14, pagadorY, 180, 6);
    pagadorY = addWrappedText(`Valor Pago: R$ ${Number(reservation.detalhesPagamento?.valorPago || 0).toFixed(2)}`, 14, pagadorY, 180, 6);
    pagadorY = addWrappedText(`Status: ${reservation.status}`, 14, pagadorY, 180, 6);

    // Rodapé
    doc.setFontSize(10);
    doc.setTextColor(150, 150, 150);
    doc.text('Obrigado por viajar conosco!', 14, 285);

    const fileName = passenger.nome ? `Reserva_${passenger.nome}.pdf` : `Reserva_${reservation.id}.pdf`;
    doc.save(fileName);
  };
};

export default exportReservationToPDF;
