import { db, auth } from '../firebaseConfig';
import { collection, doc, addDoc, getDocs, query, where, updateDoc, orderBy, limit, startAfter, getDoc } from 'firebase/firestore';
import { createDefaultLayoutForNewAgency, getLayoutById } from './LayoutService';
import { updateReservationCountForTravel } from './OrderService';
import { updateVehicleStats } from './AgencyStatistics';

const getAgencyRef = async () => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  return doc(db, 'agencies', userDoc.data().agencyId);
};

export const getVehiclesPaginated = async (pageSize = 12, lastDocument = null, filterOptions = {}) => {
  try {
    const agencyRef = await getAgencyRef();

    // Referências das coleções
    const vehiclesRef = collection(agencyRef, 'vehicles');
    const travelsRef = collection(agencyRef, 'travels');

    // Buscar contagem de viagens ativas por veículo
    const travelsSnapshot = await getDocs(
      query(
        travelsRef,
        where('estaAtivo', '==', true),
        where('status', 'not-in', ['Cancelada'])
      )
    );

    // Mapa de contagem de viagens por veículo
    const travelCountMap = {};
    travelsSnapshot.docs.forEach(doc => {
      const travel = doc.data();
      travelCountMap[travel.veiculoId] = (travelCountMap[travel.veiculoId] || 0) + 1;
    });

    // Query base
    let baseQuery = query(
      vehiclesRef,
      where('estaAtivo', '==', true),
      orderBy('identificadorVeiculo', filterOptions.sortOrder || 'asc')
    );

    // Lógica para busca
    if (filterOptions.searchTerm) {
      const searchTerm = filterOptions.searchTerm.toLowerCase().trim();
      const allDocsSnapshot = await getDocs(baseQuery);

      const filteredDocs = allDocsSnapshot.docs.filter(doc => {
        const data = doc.data();
        return (
          (data.identificadorVeiculo || '').toLowerCase().includes(searchTerm) ||
          (data.placa || '').toLowerCase().includes(searchTerm) ||
          (data.empresa || '').toLowerCase().includes(searchTerm)
        );
      });

      if (filteredDocs.length === 0) {
        return {
          vehicles: [],
          lastVisible: null,
          hasMore: false,
          totalCount: 0
        };
      }

      let startIndex = 0;
      if (lastDocument) {
        startIndex = filteredDocs.findIndex(doc => doc.id === lastDocument.id) + 1;
      }

      const paginatedDocs = filteredDocs.slice(startIndex, startIndex + pageSize);

      const vehicles = await Promise.all(
        paginatedDocs.map(async (docSnapshot) => {
          const vehicle = { id: docSnapshot.id, ...docSnapshot.data() };
          vehicle.numTravels = travelCountMap[vehicle.id] || 0;

          if (vehicle.layoutId) {
            const layoutRef = doc(agencyRef, 'layouts', vehicle.layoutId);
            const layoutDoc = await getDoc(layoutRef);
            if (layoutDoc.exists()) {
              vehicle.layout = { id: layoutDoc.id, ...layoutDoc.data() };
            }
          }

          return vehicle;
        })
      );

      return {
        vehicles,
        lastVisible: paginatedDocs[paginatedDocs.length - 1],
        hasMore: startIndex + pageSize < filteredDocs.length,
        totalCount: filteredDocs.length
      };
    }

    // Busca sem termo de pesquisa
    const snapshot = await getDocs(baseQuery);

    const sortedDocs = [...snapshot.docs].sort((a, b) => {
      const aName = a.data().identificadorVeiculo.toLowerCase();
      const bName = b.data().identificadorVeiculo.toLowerCase();
      return filterOptions.sortOrder === 'desc'
        ? bName.localeCompare(aName)
        : aName.localeCompare(bName);
    });

    let startIndex = 0;
    if (lastDocument) {
      startIndex = sortedDocs.findIndex(doc => doc.id === lastDocument.id) + 1;
    }

    const paginatedDocs = sortedDocs.slice(startIndex, startIndex + pageSize);

    const vehicles = await Promise.all(
      paginatedDocs.map(async (docSnapshot) => {
        const vehicle = { id: docSnapshot.id, ...docSnapshot.data() };
        vehicle.numTravels = travelCountMap[vehicle.id] || 0;

        if (vehicle.layoutId) {
          const layoutRef = doc(agencyRef, 'layouts', vehicle.layoutId);
          const layoutDoc = await getDoc(layoutRef);
          if (layoutDoc.exists()) {
            vehicle.layout = { id: layoutDoc.id, ...layoutDoc.data() };
          }
        }

        return vehicle;
      })
    );

    return {
      vehicles,
      lastVisible: paginatedDocs[paginatedDocs.length - 1],
      hasMore: startIndex + pageSize < sortedDocs.length,
      totalCount: sortedDocs.length
    };
  } catch (error) {
    console.error('Erro ao buscar veículos paginados:', error);
    throw error;
  }
};

export const getVehiclesCount = async () => {
  try {
    const agencyRef = await getAgencyRef();
    const vehiclesRef = collection(agencyRef, 'vehicles');
    const q = query(vehiclesRef, where('estaAtivo', '==', true));
    const snapshot = await getDocs(q);
    return snapshot.size;
  } catch (error) {
    console.error('Erro ao contar veículos:', error);
    throw error;
  }
};

// Função para adicionar um novo veículo ao Firestore
export const addVehicle = async (vehicle) => {
  try {
    const agencyRef = await getAgencyRef();
    const vehiclesRef = collection(agencyRef, 'vehicles');
    const timestamp = new Date().toISOString();

    const docRef = await addDoc(vehiclesRef, {
      ...vehicle,
      estaAtivo: true,
      agencyId: agencyRef.id, // Adiciona o ID da agência
      metadata: {
        createdAt: timestamp,
        updatedAt: timestamp,
        deletedAt: null
      }
    });
    await updateVehicleStats(1);

    return docRef;
  } catch (error) {
    console.error('Erro ao adicionar veículo:', error);
    throw error;
  }
};

// Função para atualizar os dados de um veículo específico no Firestore
export const updateVehicle = async (vehicleId, vehicleData) => {
  try {
    const agencyRef = await getAgencyRef();
    const vehicleRef = doc(agencyRef, 'vehicles', vehicleId);
    const timestamp = new Date().toISOString();

    const updatedData = {
      ...vehicleData,
      metadata: {
        ...vehicleData.metadata,
        updatedAt: timestamp
      }
    };

    return await updateDoc(vehicleRef, updatedData);
  } catch (error) {
    console.error('Erro ao atualizar veículo:', error);
    throw error;
  }
};

export const deleteVehicle = async (vehicleId) => {
  try {
    const agencyRef = await getAgencyRef();
    const vehicleRef = doc(agencyRef, 'vehicles', vehicleId);
    const timestamp = new Date().toISOString();
    await updateVehicleStats(-1);
    return await updateDoc(vehicleRef, {
      estaAtivo: false,
      metadata: {
        updatedAt: timestamp,
        deletedAt: timestamp
      }
    });
  } catch (error) {
    console.error('Erro ao excluir veículo:', error);
    throw error;
  }
};

export const getAllVehicles = async () => {
  try {
    const agencyRef = await getAgencyRef();
    const vehiclesRef = collection(agencyRef, 'vehicles');
    const q = query(vehiclesRef, where('estaAtivo', '==', true));
    const snapshot = await getDocs(q);

    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Erro ao buscar todos os veículos:', error);
    throw error;
  }
};

export const checkVehiclePlateUnique = async (placa, vehicleId = null) => {
  try {
    const agencyRef = await getAgencyRef();
    const vehiclesRef = collection(agencyRef, 'vehicles');
    const q = query(
      vehiclesRef,
      where('placa', '==', placa),
      where('estaAtivo', '==', true)
    );

    const snapshot = await getDocs(q);

    // Verifica se não encontrou nenhum veículo ou se encontrou apenas o veículo sendo editado
    return snapshot.empty || (vehicleId && snapshot.docs[0].id === vehicleId);
  } catch (error) {
    console.error('Erro ao verificar unicidade da placa:', error);
    throw error;
  }
};

// Função para obter todas as viagens associadas a um veículo específico no Firestore
export const getVehicleTravels = async (vehicleId, page = 1, itemsPerPage = 5, lastDoc = null) => {
  try {
    const agencyRef = await getAgencyRef();

    // Buscar veículo e layout em uma única query no início
    const vehicle = await getVehicleById(vehicleId);
    let layoutData = null;
    if (vehicle?.layoutId) {
      layoutData = await getLayoutById(vehicle.layoutId);
    }

    // Query base com todos os campos necessários
    let q = query(
      collection(agencyRef, 'travels'),
      where('veiculoId', '==', vehicleId),
      where('estaAtivo', '==', true),
      orderBy('dataIda', 'asc'),
      limit(itemsPerPage)
    );

    if (lastDoc) {
      q = query(q, startAfter(lastDoc));
    }

    // Buscar viagens e contagem em paralelo
    const [snapshot, countSnapshot] = await Promise.all([
      getDocs(q),
      getVehicleTravelsCount(vehicleId)
    ]);

    if (snapshot.empty) {
      return {
        travels: [],
        lastDoc: null,
        hasMore: false,
        totalCount: 0
      };
    }

    // Processar dados das viagens sem queries adicionais
    const travels = snapshot.docs.map(doc => {
      const travel = { id: doc.id, ...doc.data() };

      // Anexar dados pré-buscados do veículo e layout
      travel.veiculo = vehicle;
      if (layoutData) {
        travel.layout = layoutData;
        travel.totalSeats = layoutData.assentosTotais || 0;
      }

      return travel;
    });

    return {
      travels,
      lastDoc: snapshot.docs[snapshot.docs.length - 1],
      hasMore: travels.length === itemsPerPage,
      totalCount: countSnapshot
    };
  } catch (error) {
    console.error('Erro ao buscar viagens do veículo:', error);
    throw error;
  }
};

export const getVehicleTravelsCount = async (vehicleId) => {
  try {
    const agencyRef = await getAgencyRef();
    const travelsRef = collection(agencyRef, 'travels');

    const q = query(
      travelsRef,
      where('veiculoId', '==', vehicleId),
      where('estaAtivo', '==', true)
    );

    const snapshot = await getDocs(q);
    return snapshot.size;
  } catch (error) {
    console.error('Erro ao contar viagens do veículo:', error);
    throw error;
  }
};

export const getVehicleById = async (vehicleId) => {
  try {
    const agencyRef = await getAgencyRef();
    const vehicleRef = doc(agencyRef, 'vehicles', vehicleId);

    const vehicleDoc = await getDoc(vehicleRef);
    if (!vehicleDoc.exists()) {
      return null;
    }

    const vehicle = { id: vehicleDoc.id, ...vehicleDoc.data() };

    // Se o veículo tiver um layout, buscar em uma única operação
    if (vehicle.layoutId) {
      const layoutRef = doc(agencyRef, 'layouts', vehicle.layoutId);
      const layoutDoc = await getDoc(layoutRef);
      if (layoutDoc.exists()) {
        vehicle.layout = { id: layoutDoc.id, ...layoutDoc.data() };
      }
    }

    return vehicle;
  } catch (error) {
    console.error('Erro ao buscar veículo:', error);
    throw error;
  }
};

export const updateReservationCountsInBatch = async (travelIds) => {
  try {
    const agencyRef = await getAgencyRef();
    const batch = writeBatch(db);

    await Promise.all(travelIds.map(async (travelId) => {
      // Buscar reservas da viagem na subcoleção reservations da agência
      const reservationsRef = collection(agencyRef, 'reservations');
      const reservationsQuery = query(
        reservationsRef,
        where('travelId', '==', travelId),
        where('estaAtivo', '==', true)
      );
      const reservationsSnapshot = await getDocs(reservationsQuery);
      const reservationCount = reservationsSnapshot.size;

      // Atualizar a contagem na viagem
      const travelRef = doc(agencyRef, 'travels', travelId);
      batch.update(travelRef, { reservationCount });
    }));

    await batch.commit();
  } catch (error) {
    console.error('Erro ao atualizar contagem de reservas:', error);
    throw error;
  }
};

// Função para verificar se há conflitos de viagens para um veículo específico no Firestore
export const checkVehicleTravelConflict = async (vehicleId, dataIda, horarioIda, dataRetorno, horarioRetorno, somenteIda) => {
  try {
    const agencyRef = await getAgencyRef();
    const travelsRef = collection(agencyRef, 'travels');

    // Buscar apenas viagens ativas para o veículo especificado
    const q = query(
      travelsRef,
      where('veiculoId', '==', vehicleId),
      where('estaAtivo', '==', true)
    );

    const snapshot = await getDocs(q);

    const newTravelStart = new Date(`${dataIda}T${horarioIda}`);
    const newTravelEnd = somenteIda ? newTravelStart : new Date(`${dataRetorno}T${horarioRetorno}`);

    for (const doc of snapshot.docs) {
      const travel = doc.data();

      // Ignorar viagens canceladas ou encerradas
      if (travel.status === 'Cancelada' || travel.status === 'Encerrada') {
        continue;
      }

      const travelStart = new Date(`${travel.dataIda}T${travel.horarioIda}`);
      const travelEnd = travel.somenteIda ? travelStart : new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`);

      // Verificar sobreposição de horários
      const hasConflict = (
        (newTravelStart >= travelStart && newTravelStart <= travelEnd) || // Nova viagem começa durante uma existente
        (newTravelEnd >= travelStart && newTravelEnd <= travelEnd) ||     // Nova viagem termina durante uma existente
        (newTravelStart <= travelStart && newTravelEnd >= travelEnd)      // Nova viagem engloba uma existente
      );

      if (hasConflict) {
        return true;
      }
    }

    return false; // Nenhum conflito encontrado
  } catch (error) {
    console.error('Erro ao verificar conflito de viagens:', error);
    throw error;
  }
};
