import React from 'react';
import Box from '@mui/material/Box';
import { styled, keyframes } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import LogoLight from '../../assets/travell-logo.svg';
import LogoDark from '../../assets/travell-logo-white.svg';

// Animação de movimento da logo (Bounce)
const bounceAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;

// Animação de fade in e fade out
const fadeAnimation = keyframes`
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
`;

// Animação de Zoom Pulsante
const pulseZoomAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

// Componente Box estilizado com animação
const AnimatedBox = styled(Box)(({ theme, logo }) => ({
  width: '100px',
  height: '100px',
  backgroundImage: `url(${logo})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  animation: `
    ${bounceAnimation} 1.5s infinite ease-in-out,
    ${fadeAnimation} 2s infinite ease-in-out,
    ${pulseZoomAnimation} 2.5s infinite ease-in-out
  `,
}));

const TravelLoading = ({ disableAlignItems = false }) => {
  const theme = useTheme(); // Acessa o tema atual
  const logo = theme.palette.mode === 'dark' ? LogoDark : LogoLight; // Seleciona a logo com base no tema

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        ...(disableAlignItems ? {} : { alignItems: 'center' }), // Condicional para aplicar alignItems
      }}
    >
      <AnimatedBox logo={logo} />
    </Box>
  );
};

export default TravelLoading;
