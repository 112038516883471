import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, Typography, IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

const ReceivablesHelp = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title="Ajuda">
                <IconButton onClick={handleOpen} color="cancelar">
                    <HelpIcon />
                </IconButton>
            </Tooltip>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Ajuda - Gerenciamento de Recebíveis</DialogTitle>
                <DialogContent>
                    <Box sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                        <Typography variant="body1" paragraph>
                            Esta página exibe as informações sobre recebíveis e pagamentos relacionados aos pedidos de viagens. Os pedidos são divididos em duas categorias: "Pedidos com Pagamento Pendente" e "Pedidos Pagos".
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>1. Exibição dos Pedidos</h2>
                            Por padrão, o sistema exibe:
                            <ul>
                                <li>Pedidos de viagens cuja data de ida seja no mês atual</li>
                                <li>8 pedidos por página, podendo navegar entre as páginas usando a paginação</li>
                                <li>Pedidos pendentes e pagos são exibidos em seções separadas</li>
                                <li>Se não houver pedidos para o período selecionado, uma mensagem será exibida</li>
                            </ul>
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>2. Filtragem por Mês e Ano</h2>
                            <ul>
                                <li>Use os filtros de mês e ano para visualizar pedidos de diferentes períodos</li>
                                <li>A filtragem é baseada na data de ida da viagem</li>
                                <li>O sistema automaticamente carrega o mês e ano atual ao iniciar</li>
                                <li>É possível selecionar "Todos" para ver pedidos sem filtro de data</li>
                            </ul>
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>3. Informações de Valores</h2>
                            No topo da página são exibidos dois cards:
                            <ul>
                                <li><strong>Total a Receber:</strong> Soma de todos os valores pendentes de pagamento</li>
                                <li><strong>Total Recebido:</strong> Soma de todos os valores já pagos</li>
                                <li>Os totais são atualizados automaticamente conforme os filtros aplicados</li>
                            </ul>
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>4. Ações nos Pedidos</h2>
                            Cada pedido possui três botões de ação:
                            <ul>
                                <li><strong>Editar Pedido:</strong> Redireciona para a página de edição do pedido</li>
                                <li><strong>Cancelar Pedido:</strong> Permite cancelar o pedido inteiro (requer confirmação)</li>
                                <li><strong>Ver Detalhes:</strong> Abre um modal com informações detalhadas incluindo:
                                    <ul>
                                        <li>Status do pedido</li>
                                        <li>Informações financeiras</li>
                                        <li>Registro de pagamentos</li>
                                        <li>Detalhes dos passageiros e reservas</li>
                                        <li>Informações da viagem</li>
                                        <li>Dados do pagador</li>
                                    </ul>
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>5. Cancelamento de Pedidos</h2>
                            Ao cancelar um pedido:
                            <ul>
                                <li>Uma confirmação será solicitada</li>
                                <li>Se a senha master estiver ativa, será necessário fornecê-la</li>
                                <li>O cancelamento afeta todas as reservas do pedido</li>
                                <li>Uma notificação confirmará o sucesso ou falha da operação</li>
                            </ul>
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>6. Status dos Pedidos</h2>
                            Os pedidos podem ter três status:
                            <ul>
                                <li><strong>Pagamento Pendente:</strong> Quando há valor restante a ser pago</li>
                                <li><strong>Pago:</strong> Quando o valor total foi quitado</li>
                                <li><strong>Cancelado:</strong> Quando o pedido foi cancelado</li>
                            </ul>
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>7. Atualização dos Dados</h2>
                            Os dados são atualizados automaticamente quando:
                            <ul>
                                <li>Os filtros de mês/ano são alterados</li>
                                <li>Um pedido é editado ou cancelado</li>
                                <li>A página é alterada na paginação</li>
                            </ul>
                        </Typography>
                    </Box>
                    <Button onClick={handleClose} variant="outlined" color="primary" sx={{ mt: 2, borderRadius: '50px' }}>
                        Fechar
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default React.memo(ReceivablesHelp);