import React, { useState, useEffect } from 'react';
import { Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, Divider, CircularProgress, Pagination, Tabs, Tab, Avatar } from '@mui/material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import BusinessIcon from '@mui/icons-material/Business';
import { getVehicleTravels } from '../../services/VehicleService';
import TravelCard from '../travels/TravelCard'; 
import VehicleLayoutView from './VehicleLayoutView';
import { useTheme } from '@mui/material/styles'; 

const VehicleDetails = ({ vehicle, open, onClose }) => {
  const [travels, setTravels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(0);
  const [totalTravels, setTotalTravels] = useState(0);
  const [lastDoc, setLastDoc] = useState(null);
  const itemsPerPage = 5;
  const theme = useTheme();

  useEffect(() => {
    if (open && vehicle) {
      fetchTravels(1);
    } else {
      // Reset states when dialog closes
      setTravels([]);
      setCurrentPage(1);
      setLastDoc(null);
      setTotalTravels(0);
    }
  }, [open, vehicle]);

  const fetchTravels = async (page) => {
    setLoading(true);
    try {
      const result = await getVehicleTravels(
        vehicle.id,
        page,
        itemsPerPage,
        page === 1 ? null : lastDoc
      );
      setTravels(result.travels);
      setLastDoc(result.lastDoc);
      setTotalTravels(result.totalCount);
    } catch (error) {
      console.error('Erro ao buscar viagens do veículo:', error);
    } finally {
      setLoading(false);
    }
  };

  // Função para lidar com a mudança de página na paginação
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchTravels(value);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="vehicle-details-title" maxWidth="md" fullWidth>
      <DialogTitle id="vehicle-details-title">Detalhes do Veículo</DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={currentTab} onChange={handleTabChange} aria-label="vehicle details tabs" indicatorColor="primary" textColor="primary">
            <Tab label="Detalhes" />
            <Tab label="Layout" />
          </Tabs>
        </Box>

        {currentTab === 0 && (
          <>
            {vehicle && (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar sx={{ bgcolor: theme.palette.primary.main, mr: 2 }}>
                  <DirectionsBusIcon />
                </Avatar>
                <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 2, color: theme.palette.text.primary }}>
                  {vehicle.identificadorVeiculo}
                </Typography>
              </Box>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar sx={{ bgcolor: theme.palette.primary.main, mr: 1 }}>
                <BusinessIcon />
              </Avatar>
              <Typography variant="body2" sx={{ mb: 1, color: theme.palette.text.primary }}>
                <strong>Empresa:</strong> {vehicle.empresa}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1, ml: 2, color: theme.palette.text.primary }}>
                <strong>Placa:</strong> {vehicle.placa}
              </Typography>
            </Box>

            <Divider sx={{ mb: 2 }} />
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" sx={{ mb: 1, color: theme.palette.text.primary }}>
                <strong>Layout Associado:</strong> {vehicle.layout ? (vehicle.layout.name || 'Sem nome') : 'Nenhum layout associado'}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1, color: theme.palette.text.primary }}>
                <strong>Assentos 1° Andar:</strong> {vehicle.layout?.assentosAndar1 || 'N/A'}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1, color: theme.palette.text.primary }}>
                <strong>Assentos 2° Andar:</strong> {vehicle.layout?.assentosAndar2 || 'N/A'}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1, color: theme.palette.text.primary }}>
                <strong>Dois Andares:</strong> {vehicle.layout?.doisAndares ? 'Sim' : 'Não'}
              </Typography>
            </Box>

            {loading ? (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CircularProgress size={24} sx={{ mr: 2 }} />
                <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                  Buscando viagens...
                </Typography>
              </Box>
            ) : (
              <>
                <Divider sx={{ mb: 2 }} />
                {travels.length > 0 ? (
                  <>
                    <TravelCard travels={travels} hideActions stacked />
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                      <Pagination
                        count={Math.ceil(totalTravels / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                      />
                    </Box>
                  </>
                ) : (
                  <Typography variant="body2" sx={{ textAlign: 'center', py: 2 }}>
                    Nenhuma viagem encontrada para este veículo.
                  </Typography>
                )}
              </>
            )}
          </>
        )}

        {currentTab === 1 && vehicle?.layout && <VehicleLayoutView layout={vehicle.layout} />}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Button onClick={onClose} variant="outlined" color="primary" sx={{ borderRadius: '50px' }}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VehicleDetails;
