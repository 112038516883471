import React from 'react';

export const currencyNames = {
  BRL: {
    name: 'Brazilian Real',
    locale: 'pt-BR',
    thousand: '.',
    decimal: ',',
    symbol: 'R$',
  },
  USD: {
    name: 'US Dollar',
    locale: 'en-US',
    thousand: ',',
    decimal: '.',
    symbol: '$',
  },
};

const normalizeValue = (value) => {
  if (!value) return '0,00';
  
  // Converte para string primeiro
  const stringValue = String(value);
  
  // Remove tudo que não é número
  const numbers = stringValue.replace(/\D/g, '');
  
  // Converte para número e divide por 100 para considerar os centavos
  const amount = parseFloat(numbers || 0) / 100;
  
  // Formata o número com duas casas decimais
  return amount.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const useCurrencyInput = (initialValue = '0,00', currency = 'BRL') => {
  // Garante que o valor inicial seja formatado corretamente
  const [value, setValue] = React.useState(() => normalizeValue(initialValue));

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(normalizeValue(newValue));
  };

  const handleBlur = () => {
    setValue(normalizeValue(value));
  };

  // Converte o valor formatado para número
  const toNumber = () => {
    if (!value) return 0;
    
    const numericValue = value
      .replace(/\./g, '')  // Remove pontos
      .replace(',', '.')   // Troca vírgula por ponto
      .replace(/[^\d.-]/g, ''); // Remove caracteres não numéricos exceto ponto e sinal
      
    return parseFloat(numericValue) || 0;
  };

  return {
    value,
    setValue,
    handleChange,
    handleBlur,
    toNumber,
  };
};