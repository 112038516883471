import { db, auth } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getVehiclesCount } from './VehicleService';
import { getAllTravels } from './TravelService';

const getAgencyRef = async () => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  return doc(db, 'agencies', userDoc.data().agencyId);
};

export const updateAgencyStats = async () => {
  try {
    const agencyRef = await getAgencyRef();
    const agencyDoc = await getDoc(agencyRef);
    const agencyData = agencyDoc.data();

    // Get total vehicles count (only active vehicles)
    const totalVehicles = await getVehiclesCount();

    // Get all travels and count active ones
    const allTravels = await getAllTravels();
    const totalTravels = allTravels.filter(travel => 
      travel.estaAtivo && travel.status !== 'Cancelada'
    ).length;

    // Count total active members
    const totalUsers = Object.values(agencyData.members || {}).filter(
      member => member.metadata.status === 'active'
    ).length;

    // Get last activity timestamp
    const lastActivityAt = new Date().toISOString();

    // Update agency stats
    await updateDoc(agencyRef, {
      stats: {
        totalUsers,
        totalVehicles,
        totalTravels,
        lastActivityAt
      }
    });

    return {
      totalUsers,
      totalVehicles,
      totalTravels,
      lastActivityAt
    };
  } catch (error) {
    console.error('Error updating agency stats:', error);
    throw error;
  }
};

// Function to update stats when adding/removing vehicles
export const updateVehicleStats = async (change = 1) => {
  try {
    const agencyRef = await getAgencyRef();
    const agencyDoc = await getDoc(agencyRef);
    const currentStats = agencyDoc.data().stats || {};

    await updateDoc(agencyRef, {
      'stats.totalVehicles': (currentStats.totalVehicles || 0) + change,
      'stats.lastActivityAt': new Date().toISOString()
    });
  } catch (error) {
    console.error('Error updating vehicle stats:', error);
    throw error;
  }
};

// Function to update stats when adding/removing travels
export const updateTravelStats = async (change = 1) => {
  try {
    const agencyRef = await getAgencyRef();
    const agencyDoc = await getDoc(agencyRef);
    const currentStats = agencyDoc.data().stats || {};

    await updateDoc(agencyRef, {
      'stats.totalTravels': (currentStats.totalTravels || 0) + change,
      'stats.lastActivityAt': new Date().toISOString()
    });
  } catch (error) {
    console.error('Error updating travel stats:', error);
    throw error;
  }
};

// Function to update stats when adding/removing users
export const updateUserStats = async (change = 1) => {
  try {
    const agencyRef = await getAgencyRef();
    const agencyDoc = await getDoc(agencyRef);
    const currentStats = agencyDoc.data().stats || {};

    await updateDoc(agencyRef, {
      'stats.totalUsers': (currentStats.totalUsers || 0) + change,
      'stats.lastActivityAt': new Date().toISOString()
    });
  } catch (error) {
    console.error('Error updating user stats:', error);
    throw error;
  }
};