import { auth, db } from '../firebaseConfig';
import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, fetchSignInMethodsForEmail, sendEmailVerification, updateProfile as firebaseUpdateProfile, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { createDefaultVehicleForNewAgency } from './PopulateNewAgency';
import { createDefaultPassengerForNewAgency } from './PopulateNewAgency';

// Enum para os tipos de registro
export const RegistrationType = {
  PERSONAL: 'personal',
  BUSINESS: 'business'
};

// Função para registrar um novo usuário com email e senha
export const registerWithEmailPassword = async (registrationData) => {
  const {
    email,
    password,
    name,
    phone,
    type,
    agencyName
  } = registrationData;

  try {
    // Create user in Firebase Auth
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    if (!user) {
      throw new Error('Failed to create user.');
    }

    // Update user display name
    await firebaseUpdateProfile(user, { displayName: name });

    // Set agency name based on registration type
    const finalAgencyName = type === RegistrationType.PERSONAL
      ? `${name}`
      : agencyName;

    // Create agency document
    const agencyRef = doc(db, 'agencies', user.uid);
    await setDoc(agencyRef, {
      name: finalAgencyName,
      ownerId: user.uid,
      status: 'active',
      subscription: {
        planId: 'basic',
        startDate: new Date().toISOString(),
        endDate: null,
        status: 'trial',
        autoRenew: false
      },
      metadata: {
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        registrationType: type
      },
      stats: {
        totalUsers: 1,
        totalVehicles: 0,
        totalTravels: 0,
        lastActivityAt: new Date().toISOString()
      }
    });

    // Create user document
    const userDocRef = doc(db, 'users', user.uid);
    await setDoc(userDocRef, {
      email: user.email,
      name: name,
      phone: phone,
      type: 'agency_owner',
      agencyId: user.uid,
      isApproved: false,
      masterPassword: null,
      masterPasswordActive: false,
      metadata: {
        createdAt: new Date().toISOString(),
        lastLogin: new Date().toISOString(),
        status: 'active',
        registrationType: type
      }
    });

    // Add user as agency member with admin role
    const memberRef = doc(db, `agencies/${user.uid}/members`, user.uid);
    await setDoc(memberRef, {
      roleId: 'owner',
      customPermissions: null,
      metadata: {
        addedAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        status: 'active',
        lastAccess: new Date().toISOString()
      }
    });

    // Send verification email
    await sendEmailVerification(user);

    // Create default vehicle and passenger AFTER all documents are created
    // and wrap in try-catch to prevent registration failure
    try {
      // Force a small delay to ensure Firestore has propagated the changes
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Re-fetch the user to ensure we have the latest state
      const currentUser = auth.currentUser;
      if (currentUser) {
        await Promise.all([
          createDefaultVehicleForNewAgency(currentUser.uid),
          createDefaultPassengerForNewAgency(currentUser.uid)
        ]);
      }
    } catch (defaultsError) {
      console.error('Error creating defaults:', defaultsError);
      // Continue with registration even if defaults creation fails
    }

    return {
      user,
      agency: {
        id: user.uid,
        name: finalAgencyName,
        type: type
      }
    };
  } catch (error) {
    // Cleanup if registration fails
    if (auth.currentUser) {
      try {
        await auth.currentUser.delete();
      } catch (deleteError) {
        console.error('Error cleaning up user after registration failure:', deleteError);
      }
    }
    throw new Error(mapFirebaseError(error));
  }
};

// Função para lidar com o login do usuário
export const loginWithEmailPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    if (!user) {
      throw new Error('Error authenticating user');
    }

    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      await signOut(auth);
      throw new Error('User data not found');
    }

    const userData = userDoc.data();

    if (!userData.isApproved) {
      await signOut(auth);
      return { status: 'pending_approval' };
    }

    if (userData.agencyId) {
      const agencyRef = doc(db, 'agencies', userData.agencyId);
      const agencyDoc = await getDoc(agencyRef);

      if (!agencyDoc.exists()) {
        console.error('Agency document not found');
        await signOut(auth);
        throw new Error('Agency data not found');
      }

      const agencyData = agencyDoc.data();

      if (agencyData.status !== 'active') {
        await signOut(auth);
        return { status: 'agency_inactive' };
      }

      if (agencyData.subscription?.status === 'expired') {
        await signOut(auth);
        return { status: 'subscription_expired' };
      }

      await createDefaultVehicleForNewAgency(user.uid);
      await createDefaultPassengerForNewAgency(user.uid);

      return {
        status: 'success',
        user,
        userData: {
          ...userData,
          agency: agencyData
        }
      };
    }

    return {
      status: 'success',
      user,
      userData
    };

  } catch (error) {
    console.error('Login error:', error);
    const errorMessage = mapFirebaseError(error);
    throw new Error(errorMessage);
  }
};

// Função para fazer logout do usuário atual
export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    throw new Error(mapFirebaseError(error));
  }
};

export const checkEmailExists = async (email) => {
  try {
    const methods = await fetchSignInMethodsForEmail(auth, email);
    return methods.length > 0; // Retorna true se o email já existe
  } catch (error) {
    console.error('Erro ao verificar email:', error);
    throw new Error(mapFirebaseError(error));
  }
};

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    throw new Error(mapFirebaseError(error));
  }
};

// Função para verificar se a senha master está definida
export const checkMasterPasswordExists = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Usuário não está autenticado');

    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (!userDoc.exists()) return false;

    return !!userDoc.data().masterPassword;
  } catch (error) {
    console.error('Erro ao verificar senha master:', error);
    throw error;
  }
};

export const getMasterPasswordStatus = async () => {
  try {
    const user = auth.currentUser;

    if (!user) {
      return { isActive: false, isDefined: false };
    }

    const userDoc = await getDoc(doc(db, 'users', user.uid));

    if (!userDoc.exists()) {
      return { isActive: false, isDefined: false };
    }

    const userData = userDoc.data();

    const result = {
      isActive: Boolean(userData.masterPasswordActive),
      isDefined: Boolean(userData.masterPassword)
    };

    return result;
  } catch (error) {
    console.error('Erro ao obter status da senha master:', error);
    return { isActive: false, isDefined: false };
  }
};

export const getMasterPasswordFullStatus = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Usuário não está autenticado');

    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (!userDoc.exists()) return { isActive: false, isDefined: false };

    const userData = userDoc.data();
    return {
      isActive: Boolean(userData.masterPasswordActive),
      isDefined: Boolean(userData.masterPassword)
    };
  } catch (error) {
    console.error('Erro ao obter status completo da senha master:', error);
    throw error;
  }
};

export const toggleMasterPasswordActive = async (isActive) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Usuário não está autenticado');

    const userRef = doc(db, 'users', user.uid);
    await updateDoc(userRef, { masterPasswordActive: isActive });
  } catch (error) {
    console.error('Erro ao alternar status da senha master:', error);
    throw error;
  }
};

export const updateMasterPassword = async (currentMasterPassword, newMasterPassword) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Usuário não está autenticado');

    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      await setDoc(userRef, {
        masterPassword: newMasterPassword,
        masterPasswordActive: true,
        metadata: {
          createdAt: new Date().toISOString(),
          status: 'active'
        }
      });
      return;
    }

    const userData = userDoc.data();
    if (userData.masterPassword && userData.masterPassword !== currentMasterPassword) {
      throw new Error('Senha master atual incorreta');
    }

    await updateDoc(userRef, {
      masterPassword: newMasterPassword,
      masterPasswordActive: true
    });
  } catch (error) {
    console.error('Erro ao atualizar senha master:', error);
    throw error;
  }
};

// Função personalizada para atualizar o perfil do usuário
export const updateUserProfile = async (profileUpdates) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Usuário não está autenticado.');

    await firebaseUpdateProfile(user, profileUpdates);

    const userDocRef = doc(db, 'usuarios', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      await updateDoc(userDocRef, {
        displayName: profileUpdates.displayName,
        email: user.email,
        isApproved: userDoc.data().isApproved
      });
    } else {
      await setDoc(userDocRef, {
        displayName: profileUpdates.displayName,
        email: user.email,
        isApproved: false
      });
    }

  } catch (error) {
    console.error('Erro ao atualizar perfil do usuário:', error);
    throw new Error(mapFirebaseError(error));
  }
};

// Função para mapear os códigos de erro do Firebase para mensagens mais amigáveis
const mapFirebaseError = (error) => {
  switch (error.code || error.message) {
    case 'auth/user-not-found':
      return 'Usuário não encontrado. Verifique suas credenciais e tente novamente.';
    case 'auth/invalid-credential':
      return 'Credenciais incorretas. Tente novamente.';
    case 'auth/email-already-in-use':
      return 'O endereço de e-mail já está em uso por outra conta.';
    case 'auth/invalid-email':
      return 'O endereço de e-mail não é válido.';
    case 'auth/weak-password':
      return 'A senha é muito fraca. Tente uma senha mais forte.';
    case 'auth/network-request-failed':
      return 'Falha na rede. Verifique sua conexão e tente novamente.';
    case 'auth/email-not-verified':
      return 'Por favor, verifique seu e-mail antes de fazer login.';
    default:
      return error.message || 'Ocorreu um erro inesperado. Tente novamente mais tarde.';
  }
};

// Exportação de funções adicionais para atualização de perfil e senha
export {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
};
