import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  CssBaseline,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
  Tooltip,
  Collapse
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PaymentIcon from '@mui/icons-material/Payment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import Logo from '../../assets/travell-logo-white.svg';
import { logout } from '../../services/AuthService';
import { useAuth } from '../../contexts/useAuthState';
import { useDrawer } from '../../contexts/DrawerContext';
import { useIdleTimer } from 'react-idle-timer';
import { isBusinessAgency } from '../../services/AgencyService';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, showSidebar }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: showSidebar ? `-${drawerWidth}px` : 0,
    ...(open && showSidebar && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  })
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Layout = ({ children, showSidebar = true, defaultOpenDrawer = true, hideLogout = false, toggleColorMode, mode }) => {
  const theme = useTheme();
  const { openDrawer, toggleDrawer, closeDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openVehicleSubMenu, setOpenVehicleSubMenu] = useState(false);
  const [showAgencyMenu, setShowAgencyMenu] = useState(false);

  useEffect(() => {
    const checkAgencyType = async () => {
      if (currentUser?.uid) {
        const isBusiness = await isBusinessAgency(currentUser.uid);
        setShowAgencyMenu(isBusiness);
      }
    };

    checkAgencyType();
  }, [currentUser]);

  useEffect(() => {
    if (!defaultOpenDrawer) {
      closeDrawer();
    }
  }, [defaultOpenDrawer, closeDrawer]);

  const handleLogout = async () => {
    setLoading(true);
    try {
      await logout();
    } catch (error) {
      console.error("Falha ao sair:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleVehicleSubMenuClick = () => {
    setOpenVehicleSubMenu(!openVehicleSubMenu);
  };

  const handleOnIdle = () => {
    setSnackbarOpen(true);
  };

  const idleTimer = useIdleTimer({
    timeout: 300000,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const handleSnackbarClick = () => {
    navigate('/central-ajuda');
  };

  const isSelected = (path) => location.pathname === path;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {showSidebar && (
            <Tooltip title="Menu">
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawer}
                sx={{ mr: 2, color: 'white' }}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          )}
          <RouterLink to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
            <img src={Logo} alt="Logo" style={{ height: 30, marginRight: 5 }} />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          {/* Botão de alternância de tema */}
          <Tooltip title={mode === 'dark' ? 'Ativar modo claro' : 'Ativar modo escuro'}>
            <IconButton
              onClick={toggleColorMode}
              aria-label="Theme toggle button"
              size="small"
              sx={{
                marginRight: 1,
                color: 'white'  // Define a cor do ícone como branco
              }}
            >
              {mode === 'dark' ? <WbSunnyRoundedIcon fontSize="small" /> : <ModeNightRoundedIcon fontSize="small" />}
            </IconButton>
          </Tooltip>

          <RouterLink to="/central-ajuda" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
            <Tooltip title="Ajuda">
              <IconButton
                sx={{
                  marginRight: 1,
                  color: 'white'  // Define a cor do ícone como branco
                }}
              >
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </RouterLink>

          {!hideLogout && !loading && currentUser && (
            <Tooltip title="Sair">
              <IconButton
                onClick={handleLogout}
                sx={{
                  color: 'white'  // Define a cor do ícone como branco
                }}
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          )}
          {loading && !hideLogout && <CircularProgress color="inherit" size={24} />}
        </Toolbar>
      </AppBar>

      {showSidebar && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={openDrawer}
        >
          <DrawerHeader>
            <IconButton onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem
              button
              component={RouterLink}
              to="/"
              selected={isSelected('/')}
              sx={{
                borderRadius: '8px', // Sempre tem a borda arredondada
                transition: 'background-color 0.3s, color 0.3s', // Transição suave
                '&:hover': {
                  backgroundColor: theme.palette.action.hover, // Cor ao passar o mouse
                },
                ...(isSelected('/') && {
                  backgroundColor: '#e0e0e0', // Cor de fundo quando selecionado
                  color: '#000', // Cor do texto quando selecionado
                }),
              }}
            >
              <ListItemIcon sx={{ color: isSelected('/') ? '#000' : 'inherit' }}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText secondary="Início" />
            </ListItem>

            <ListItem
              button
              component={RouterLink}
              to="/viagens"
              selected={isSelected('/viagens')}
              sx={{
                borderRadius: '8px',
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                ...(isSelected('/viagens') && {
                  backgroundColor: '#e0e0e0',
                  color: '#000',
                }),
              }}
            >
              <ListItemIcon sx={{ color: isSelected('/viagens') ? '#000' : 'inherit' }}>
                <EditRoadIcon />
              </ListItemIcon>
              <ListItemText secondary="Viagens" />
            </ListItem>

            <ListItem
              button
              component={RouterLink}
              to="/passageiros"
              selected={isSelected('/passageiros')}
              sx={{
                borderRadius: '8px',
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                ...(isSelected('/passageiros') && {
                  backgroundColor: '#e0e0e0',
                  color: '#000',
                }),
              }}
            >
              <ListItemIcon sx={{ color: isSelected('/passageiros') ? '#000' : 'inherit' }}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText secondary="Passageiros" />
            </ListItem>

            <ListItem button onClick={handleVehicleSubMenuClick} sx={{ borderRadius: '8px', '&:hover': { backgroundColor: theme.palette.action.hover } }}>
              <ListItemIcon>
                <DirectionsBusIcon />
              </ListItemIcon>
              <ListItemText secondary="Veículos" />
              {openVehicleSubMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openVehicleSubMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  component={RouterLink}
                  to="/veiculos"
                  selected={isSelected('/veiculos')}
                  sx={{
                    borderRadius: '8px',
                    pl: 4,
                    transition: 'background-color 0.3s',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                    ...(isSelected('/veiculos') && {
                      backgroundColor: '#e0e0e0',
                    }),
                  }}
                >
                  <ListItemText secondary="Gerenciar Veículos" />
                </ListItem>

                <ListItem
                  button
                  component={RouterLink}
                  to="/veiculos/layout"
                  selected={isSelected('/veiculos/layout')}
                  sx={{
                    borderRadius: '8px',
                    pl: 4,
                    transition: 'background-color 0.3s',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                    ...(isSelected('/veiculos/layout') && {
                      backgroundColor: '#e0e0e0',
                    }),
                  }}
                >
                  <ListItemText secondary="Layout de Assentos" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              button
              component={RouterLink}
              to="/pedidos-e-reservas"
              selected={isSelected('/pedidos-e-reservas')}
              sx={{
                borderRadius: '8px',
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                ...(isSelected('/pedidos-e-reservas') && {
                  backgroundColor: '#e0e0e0',
                  color: '#000',
                }),
              }}
            >
              <ListItemIcon sx={{ color: isSelected('/pedidos-e-reservas') ? '#000' : 'inherit' }}>
                <EventNoteIcon />
              </ListItemIcon>
              <ListItemText secondary="Pedidos e Reservas" />
            </ListItem>

            <ListItem
              button
              component={RouterLink}
              to="/pagamentos"
              selected={isSelected('/pagamentos')}
              sx={{
                borderRadius: '8px',
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                ...(isSelected('/pagamentos') && {
                  backgroundColor: '#e0e0e0',
                  color: '#000',
                }),
              }}
            >
              <ListItemIcon sx={{ color: isSelected('/pagamentos') ? '#000' : 'inherit' }}>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText secondary="Pagamentos" />
            </ListItem>

            <ListItem
              button
              component={RouterLink}
              to="/relatorios"
              selected={isSelected('/relatorios')}
              sx={{
                borderRadius: '8px',
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                ...(isSelected('/relatorios') && {
                  backgroundColor: '#e0e0e0',
                  color: '#000',
                }),
              }}
            >
              <ListItemIcon sx={{ color: isSelected('/relatorios') ? '#000' : 'inherit' }}>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText secondary="Relatórios" />
            </ListItem>

            {/* <ListItem
              button
              component={RouterLink}
              to="/usuarios"
              selected={isSelected('/usuarios')}
              sx={{
                borderRadius: '8px',
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                ...(isSelected('/usuarios') && {
                  backgroundColor: '#e0e0e0',
                  color: '#000',
                }),
              }}
            >
              <ListItemIcon sx={{ color: isSelected('/usuarios') ? '#000' : 'inherit' }}>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText secondary="Usuários" />
            </ListItem> */}


          </List>
          <Box sx={{ mt: 'auto', mb: 2, px: 2 }}>
            <Divider sx={{ mb: 1 }} />

            {showAgencyMenu && (
              <Tooltip title="Informações da Agência">
                <ListItem
                  button
                  component={RouterLink}
                  to="/agencia"
                  selected={isSelected('/agencia')}
                  sx={{
                    borderRadius: '8px',
                    transition: 'background-color 0.3s, color 0.3s',
                    py: 1, // Reduzido de 1.5 para 1
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                    ...(isSelected('/agencia') && {
                      backgroundColor: theme.palette.action.selected,
                      color: theme.palette.text.primary,
                    }),
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 20,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: isSelected('/agencia') ? theme.palette.primary.main : theme.palette.text.secondary,
                    }}
                  >
                    <BusinessIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Minha Agência"
                    primaryTypographyProps={{
                      variant: 'body2',
                      color: theme.palette.grey[600],
                    }}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      m: 0 // Remove margens extras
                    }}
                  />
                </ListItem>
              </Tooltip>
            )}

            <Tooltip title="Minha Conta">
              <ListItem
                button
                component={RouterLink}
                to="/conta"
                selected={isSelected('/conta')}
                sx={{
                  borderRadius: '8px',
                  transition: 'background-color 0.3s, color 0.3s',
                  py: 1.5,
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                  ...(isSelected('/conta') && {
                    backgroundColor: theme.palette.action.selected,
                    color: theme.palette.text.primary,
                  }),
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: isSelected('/conta') ? theme.palette.primary.main : theme.palette.text.secondary,
                  }}
                >
                  <AccountCircleIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    color: theme.palette.grey[600],
                  }}
                  secondaryTypographyProps={{
                    color: theme.palette.grey[500],
                  }}
                  primary={currentUser?.displayName || 'Minha Conta'}
                  secondary={currentUser?.email || 'email@example.com'}
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                />
              </ListItem>
            </Tooltip>
          </Box>
        </Drawer>
      )}

      <Main open={showSidebar && openDrawer} showSidebar={showSidebar}>
        <DrawerHeader />
        {children}
      </Main>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            // Evita fechar se o clique for fora da snackbar
            return;
          }
          setSnackbarOpen(false); // Fecha a snackbar
        }}
      >
        <Alert
          severity="info"
          sx={{ width: '100%', cursor: 'pointer' }}
          onClick={handleSnackbarClick} // Redireciona ao clicar no corpo da snackbar
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(event) => {
                event.stopPropagation(); // Impede que o clique feche a snackbar e redirecione ao mesmo tempo
                setSnackbarOpen(false); // Fecha a snackbar
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          Parece que você está inativo. Caso precise de ajuda, <strong>clique aqui</strong> ou pressione F1 para acessar a Central de Ajuda.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Layout;
